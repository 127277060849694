import React from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import './Account.css';
import {AccountStyle} from './AccountStyle';
import LoadingOverlay from '../common/loadingOverlay';
import { Button, Grid } from '@material-ui/core';
import NewAccount from './NewAccount';
import mapStateToProps from '../actions/stateToProps';
import mapDispatchToProps from '../actions/dispatchProps';
import Constants from '../utils/constants';
import { AgGridReact } from 'ag-grid-react';
import AccountCard from './AccountCard';
import { AccountGrid } from './AccountGrid';
import { AccountHandler } from '../api';
import { withSnackbar } from 'notistack';
import NewAccountChitInfo from './NewAccountChitInfo';
import ListItemHandler from '../api/ListItemHandler';
import {WebClientStore} from 'web-client-store';

let _this = null;
let defaultMode = Constants.ACTIVE_ACCOUNT_GRID_MODES.CARDS;
let {Local, Session} = WebClientStore;
class Account extends React.Component {
    
    constructor(props) {
        super(props);
        _this = this;
        this._handler = new AccountHandler(this);
        this._handlerListItems = new ListItemHandler(this);
        this.handleSearchChange = this.handleSearchChange.bind(this)
        this.handleResetAllFilters = this.handleResetAllFilters.bind(this)
        this.onSelectionChanged = this.onSelectionChanged.bind(this)
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    updateWindowDimensions() {
        let _lightMode = Constants.ACTIVE_ACCOUNT_GRID_MODES.LIGHT;
        let changeViewMode = window.innerWidth <= 420 ? _lightMode : this.state.gridMode;
        this.setState({ 
            width: window.innerWidth, 
            height: window.innerHeight,
            gridMode: changeViewMode
        });
        this.props.setScreenWidth(window.innerWidth);
        this.props.setViewPort(window.innerWidth <= 420 ? 'small' : 'other')
        this.props.setPlatForm(window.isAndroid() ? 'android' : 'web')
    }

    getDefaultMode = ()=>{
        let key = Constants.LOCAL_KEYS.ACTIVE_ACCOUNT_GRID_MODE;
        if (Local.get(key)) {
            return Number(Local.get(key))
        } else {
            Local.set(key, defaultMode);
        }
        return defaultMode;
    }

    handleSetMode = (gridMode=defaultMode) => {
        let key = Constants.LOCAL_KEYS.ACTIVE_ACCOUNT_GRID_MODE;
        Local.set(key, gridMode);
        this.setState({gridMode, enabledUpdate: false})
    }

    state = {
        width: 0,
        height: 0,
        openOverlay: false,
        accounts: [],
        filteredAccounts: [],
        enableAdd: false,
        enableAddChitInfo: false,
        enabledUpdate: false,
        showUpdateModal: false,
        typeMap: {'DEF': 'Default Account'},
        accountTypes: [],
        searchTerm: '',
        accountFilterType: 'show_all',
        selectedAccount: { _id: 'unkonwn' },
        rowsSelected: {},
        gridMode: this.getDefaultMode(),
        newAccount: {}
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        this.props.changePageTitle('Accounts');
        this.handleGetAccounts(true);
    }
      
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    
    handleSearchChange = (e) => this.filterAccounts(e.target.value);
    handleResetAllFilters = () => this.filterAccounts('')
    filterAccounts = (query) => {
        let _accounts = this.state.accounts;
        try {
            let _filtered = _accounts.filter((ac)=>{
                return ac.name?.toLowerCase().includes(query.toLowerCase()) ||
                ac.code?.toLowerCase().includes(query.toLowerCase());
            })
            this.setState({
                searchTerm: query,
                filteredAccounts: (query === '' ? _accounts : _filtered)
            })
        } catch (error) {
            console.error(error);
        }
    }

    handleReActivateAccount = (acId) => {
        let hooks = {
            before: () => this._handler.nav.showOverlay(),
            after: () => {
                this._handler.nav.hideOverlay();
                this._handler.showSuccess(`Account re-activated successfully.`);
            },
            // error: () => {}
        }
        // this.setState({ openOverlay: true })
        this._handler.unArchiveAccount(acId, hooks).then(()=>{
            this.handleGetAccounts(true);
            // this.setState({ openOverlay: false })
        })
    }

    handleFilterAccounts = (type='') => {
        let _searchTerm = this.state.searchTerm;
        let _accounts = this.state.accounts.filter(ac=>(ac.status==='ac'));
        let _filtered = _accounts.filter(ac=>(ac.type === type && ac.status === 'ac'))
        this.setState({
            searchTerm: (type === '' ? '' : _searchTerm),
            filteredAccounts: (type === '' ? _accounts : _filtered),
            accountFilterType: (type === '' ? 'show_all' : type)
        })
    }

    handleFilterAccountsSharedOnly = () => {
        let _accounts = this.state.accounts;
        let _filtered = _accounts.filter(ac=>!ac.isOwner)
        this.setState({
            filteredAccounts: _filtered,
            accountFilterType: 'shared'
        })
    }

    handleFilterAccountsArchivedOnly = () => {
        let _accounts = this.state.accounts;
        let _filtered = _accounts.filter(ac=> (ac.status === 'cl'))
        this.setState({
            filteredAccounts: _filtered,
            accountFilterType: 'archive'
        })
    }

	handleGetAccounts = async (refresh=false) => {
        this.setState({ openOverlay: true });
        let _type = 'error';
        let _msg = '';
        let _accounts = this.state.accounts;
        //dummy initialize a promise to start conditional execution.
        let request = Promise.resolve(refresh);
        request
            .then((condition)=>{
                return this._handler.getAccountsCached(condition);
            })
            .then((data) => {
                // console.log(data);
                _accounts = data;
            })
            .catch((err) => {
                console.log('error while fetching accounts:' + err.message);
                _msg = err.message;
                _type = err.type;
            })
            .finally(()=>{
                if (_type === 'timeout') {
                    this.props.enqueueSnackbar(_msg, { variant: 'error'})
                    this.props.history.push('/logout');
                }
                this.handleGetAcctTypes(_accounts);
            });
    }

    handleGetAcctTypes = async (accounts) => {
        // console.log('Account.js -> handleGetAcctTypes')
        let typeMap = [];
        this._handlerListItems.getAcctTypes()
		.then((data) => {
            // console.log(data);
            data.forEach((type, index) => {
                typeMap[type.code] = type.name;
            });
            // this.setState({ typeMap });
		})
		.catch((err) => {
			console.log('error while fetching account types:' + err.message);
        })
        .finally(()=>{
            this.handleFilterAcctTypes(accounts, typeMap)
        });
	}

    handleFilterAcctTypes = async (_accounts, typeMap) => {
        let types = _accounts.reduce((obj, account)=>{
            if (account.status === 'ac')
                obj[account.type] = true;
            return obj;
        },{});
        let acTypes = Object.keys(types).reduce((arr, type)=>{
            arr.push({
                code: type,
                name: typeMap[type]
            })
            return arr;
        },[])
        // this.setState({ accountTypes: acTypes });
        
        this.setState({ 
            openOverlay: false, 
            accounts: _accounts,
            filteredAccounts: _accounts.filter(ac=>(ac.status==='ac')),
            accountTypes: acTypes,
            typeMap
        });
    }
    
    render() {
        return this.defaultView2();
    }
    
    defaultView2() {
        let _modes = Constants.ACTIVE_ACCOUNT_GRID_MODES;
        return (
          <div className="content-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    {/* <h4 className="card-title">Your Active Accounts</h4>
                    <p className="card-category">List of your financial accounts</p> */}
                    {this.renderNewAccountAction()}
                  </div>
                  <div className="card-body">
                    {this.renderToolBar()}
                    {this.state.accounts.length > 0 && this.state.gridMode === _modes.CARDS && this.renderCardGrid()}
                    {this.state.accounts.length > 0 && this.state.gridMode !== _modes.CARDS && this.renderAGGrid()}
                    {this.state.enableAdd && this.renderNewAccountForm()}
                    {this.state.enableAddChitInfo && this.renderNewAccountChitInfoForm()}
                    {this.state.showUpdateModal && this.renderUpdateAccountForm()}
                    {this.state.openOverlay && <LoadingOverlay />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
    }


    renderNewAccountAction() {
        let accId = '';
        if (this.state.enabledUpdate) {
           accId = this.gridApi.getSelectedRows()[0]._id;
        }
        return (
            <Grid container id="trans-actions">
                <Grid item md={12} sm={12} xs={12} id="trans-actions-left">
                    <Button onClick={() => this.setState({ enableAdd: true })}
                        variant="contained"
                        size="large"
                        style={{float: "right"}}
                        color="primary">
                        Add Account
                    </Button>
                    {this.state.enabledUpdate &&
                        <Button onClick={() => this.props.history.push(`/accounts/ac/${accId}`)}
                            variant="contained"
                            size="large"
                            style={{float: "right"}}
                            color="inherit">
                            Edit
                    </Button>}
                </Grid>
            </Grid>
        )
    }
    
    renderToolBar() {
        // let _this = this;
        let sharedCnt = this.state.accounts.filter(ac=>!ac.isOwner).length;
        let archivedCnt = this.state.accounts.filter(ac=>(ac.status==='cl')).length;
        // console.log('archivedCnt', archivedCnt)
        return (
            <Grid container id="trans-actions">
                <Grid item md={8} sm={12} xs={12} /* style={{backgroundColor: 'pink'}} */>
                    {/* <div style={{ paddingLeft: 12 }}> */}
                        {this.state.accountTypes.map(function (type, index) {
                            let icon = <img
                                width="40px"
                                src={`../assets/img/accounts/icons/${type.code}.png`}
                                alt={_this.state.typeMap[type.code]}
                                className={`account-filter-icon ${_this.state.accountFilterType === type.code ? 'active' : ''}`} />;
                            return <span key={type.code}
                                    onClick={() => _this.handleFilterAccounts(type.code)}
                                    >{icon}</span>;
                        })}
                        {sharedCnt > 0 && <span key="shared" style={{'cursor': 'pointer'}}
                            onClick={()=>_this.handleFilterAccountsSharedOnly()}
                            className={`btn btn-success account-filter-icon ${_this.state.accountFilterType === 'shared' ? 'active' : ''}`}
                            >Shared&nbsp;&nbsp;<i className="pe-7s-users" style={{fontSize: 18}}></i></span>}&nbsp;
                        <span key="all" style={{'cursor': 'pointer'}}
                            onClick={() => _this.handleFilterAccounts('')}
                            className={`btn btn-warning account-filter-icon ${_this.state.accountFilterType === 'show_all' ? 'active' : ''}`}
                            >Show All</span>&nbsp;
                        {archivedCnt > 0 && <span key="archive" style={{'cursor': 'pointer'}}
                            onClick={()=>_this.handleFilterAccountsArchivedOnly()}
                            className={`btn btn-danger account-filter-icon ${_this.state.accountFilterType === 'archive' ? 'active' : ''}`}
                            >Archived</span>}
                    {/* </div> */}
                </Grid>
                <Grid item md={4} sm={12} xs={12} /* style={{backgroundColor: 'yellow'}} */>
                    {/* <div style={{ float: "right", marginRight: 20 }}> */}
                        {/* <label forhtml="quickSearch">Search:</label> */}

                        <span key="all" style={{'cursor': 'pointer'}}
                            onClick={() => _this.handleGetAccounts(true)}
                            className={`btn btn-warning account-filter-icon active`}
                            >Reload</span>&nbsp;
                        <input id="quickSearch" type="name" placeholder="Search here"
                            value={this.state.searchTerm}
                            style={{maxWidth: 200}}
                            onChange={this.handleSearchChange} />
                        {this.state.searchTerm !== '' && <label forhtml="quickSearch"><span 
                            onClick={() => this.handleResetAllFilters()}
                            ><i className="pe-7s-close" style={{fontSize: 32, fontWeight: "bolder"}}></i></span>
                            </label>}
                        {/* <Button
                            onClick={() => this.handleResetAllFilters()}
                            variant="contained"
                            size="small"
                            style={{ height: 28, marginBottom: 4, marginRight: 10, marginTop: 2 }}
                            color="default">Reset</Button> */}
                        {/* <div style={{ float: "right" }}> */}
                            <span onClick={() => this.handleSetMode(1)} style={{ float: "right" }}>
                                <i className="pe-7s-credit" style={{ fontSize: '30px', fontWeight: 'bold', cursor: 'pointer' }}></i>
                            </span>
                            <span onClick={() => this.handleSetMode(2)} style={{ float: "right" }}>
                                <i className="pe-7s-menu" style={{ fontSize: '30px', fontWeight: 'bold', cursor: 'pointer', color: 'white', backgroundColor: 'black' }}></i>
                            </span>
                            <span onClick={() => this.handleSetMode(3)} style={{ float: "right" }}>
                                <i className="pe-7s-menu" style={{ fontSize: '30px', fontWeight: 'bold', cursor: 'pointer', marginRight: 4 }}></i>
                            </span>
                        {/* </div> */}
                    {/* </div> */}
                </Grid>
            </Grid>
        )
    }

    renderCardGrid() {
        return (
            <AccountCard 
                accounts={this.state.filteredAccounts} 
                showEditIcon={true}
                onEdit={accountId => this.props.history.push(`/accounts/ac/${accountId}`)}
                showViewTransIcon={true}
                onViewTrans={accountId => this.props.history.push(`/transactions/${accountId}`)}
                onUnlock={accountId => this.handleReActivateAccount(accountId)}
                zoomIn={true}
            />
        )
    }

	handleOnGridReady = params => {
        this.gridApi = params.api;
    }

	onSelectionChanged() {
		let flagUpdate = false;
		let selectedRows = this.gridApi.getSelectedRows();
		let oneRow = selectedRows[0];
		if (oneRow && oneRow._id && oneRow._id !== '') {
			//proceed
		} else {
			return;
		}
		flagUpdate = selectedRows.length === 1 && (this.state.gridMode === 2 || this.state.gridMode === 3 );
		this.setState({ 
			rowsSelected: oneRow, 
			enabledUpdate: flagUpdate,
		})
    }
    
    renderAGGrid() {
        const { classes } = this.props;
        let _modes = Constants.ACTIVE_ACCOUNT_GRID_MODES;
        let gridDef = AccountGrid(this.state.typeMap);
        const agStyles = `ag-theme-${this.state.gridMode === _modes.DARK ? 'alpine-dark' : 'balham'} ${classes.agThemeModified}`;
		return (
			<Grid container id='trans-tran-grid' /* style={{"height": "70%", width: "96%", marginLeft: "2%", marginTop: "10px"}} */ >
				<Grid item  md={12} sm={12} xs={12}>
					<div className={agStyles}>
						<AgGridReact
							gridOptions={{
								defaultColDef: {
                                    resizable: true,
                                    sortable: true,
								}
							}}
							// onRowDragEnter={this.onRowDragEnter}
							// onRowDragEnd={this.onRowDragEnd}
							onGridReady={this.handleOnGridReady}
							// onRowDragLeave={this.onRowDragLeave}
							columnDefs={this.state.width > 420 ? gridDef.colDefWide : gridDef.colDefCompact}
							rowData={this.state.filteredAccounts}
							// getRowClass={this.handleRowHighlights}
							rowSelection="single"
							onSelectionChanged={this.onSelectionChanged.bind(this)}
							// rowDragManaged={true}
							animateRows
							// pagination={!this.state.gridEditMode}
                            // paginationPageSize={25}
                            enableCellTextSelection="true"
                            ensureDomOrder="true"
						/>
					</div>
				</Grid>
			</Grid>
        );
    }

    renderNewAccountForm() {
        return (
            <NewAccount 
                display={this.state.enableAdd}
                onCancel={() => {
                    this.setState({ enableAdd: false });
                }} 
                onSubmit={() => {
                    this.setState({ enableAdd: false });
                    this.handleGetAccounts(true);
                }} 
                onInitChitInfo={(newAccount) => {
                    this.setState({
                        newAccount,
                        enableAdd: false,
                        enableAddChitInfo: true
                    })
                }}
                modeUpdate={false}
                asDialog={true}
                account={this.state.selectedAccount}
            />
        )
    }
    
    renderNewAccountChitInfoForm() {
        return (
            <NewAccountChitInfo 
                display={this.state.enableAddChitInfo}
                onCancel={() => {
                    this.setState({ enableAddChitInfo: false });
                }} 
                onSubmit={() => {
                    this.setState({ enableAddChitInfo: false });
                    this.handleGetAccounts(true);
                }} 
                modeUpdate={false}
                asDialog={true}
                account={this.state.newAccount}
                termStarted={false} //During New account creation
            />
        )
    }

    renderUpdateAccountForm() {
        return (
            <NewAccount 
                display={this.state.showUpdateModal}
                onCancel={() => {
                    this.setState({ showUpdateModal: false });
                }} 
                onSubmit={() => {
                    this.setState({ showUpdateModal: false, enabledUpdate: false });
                    Session.set('force_refresh', true)
                    this.handleGetAccounts(true);
                }} 
                modeUpdate={true}
                asDialog={true}
                account={this.state.rowsSelected}
            />
        )
    }

}

Account.propTypes = {
    classes: PropTypes.object.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(withStyles(AccountStyle)(Account)));
