const Messenger = {
    
    getAccountArchiveConfirmation(isReactivate=false,ln='en') {
        if (isReactivate) {
            return `Are you sure to re-activate this account?`
        } else
        return `Archiving is a process of hiding an account from your active accounts and activities. \n\r
        This is not closing account but essentially making it inactive. You can make it active from this page. \n\r\n\r
        Are you sure to archive this account?`;
    }
}

export default Messenger;
