import React from 'react';
import PropTypes from 'prop-types';
import '../../../node_modules/react-vis/dist/style.css';
import '../widgets/widgetstyles.css';
import { getInstance } from '../utils/axiosLoader';
import Constants from '../utils/constants';
import { Button, Grid } from '@material-ui/core';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import { WebClientStore } from 'web-client-store';
let {Session} = WebClientStore;

class ChitPayDashboard extends React.Component {
  state = {

    entries: [],
    columnDefs: [],
    data: {
      // validFound: false,
      // valid: [],
      // all: [{
      //   pct: 100,
      //   key: 'unknown',
      //   label: 'unknown (100%)'
      // }]
    },
    // includeUnknown: true,
    // activeCr: -1,
    // activeDr: -1,
    // balValue: null,
    // curr: Session.get(Constants.SESSION_KEYS.ACTIVE_ACCOUNT_CURR) || 'USD',
    // loading: false
  };

  componentDidMount() {
    this.handleReloadDashboard()
  }

  handleReloadDashboard = async function () {
    let ac = Session.get(Constants.SESSION_KEYS.ACTIVE_ACCOUNT);
    this.setState({ loading: true })
    await getInstance()
      .get(Constants.URLs.TRANS + '/chit-tran-status/account/' + ac + '?test')
      .then((response) => {
        let subs = response.data.statusTable;
        // {
        //   "statusTable": {
        //     "Subscriber 1": [
        //       {
        //         "id": "605454451869d421a8019eb3",
        //         "term": 1,
        //         "date": "2021-Mar-15",
        //         "status": "unpaid",
        //         "type": "chit_contribution",
        //         "sub": "Subscriber 1"
        //       },
        let columns = {};
        let _rows = [];
        let _subs = Object.keys(subs);
        _subs.forEach(_sub=>{
          let _payments = subs[_sub];
          let _row = {
            sub: _sub
          };
          _payments.forEach(_payment=>{
            if (_payment.subId) _row['id'] = _payment.subId;
            _row[_payment.term] = _payment.status;
            columns[_payment.term] = true;
          })
          _rows.push(_row);
        })
        let def = [{
          headerName: 'Subscriber',
          width: 160,
          field: 'sub',
          cellStyle: (params) => {
            return {color: 'blue', 'font-size': 'medium'};
          }
        }];
        Object.keys(columns).forEach((col)=>{
          def.push({
            headerName: `${col}`,
            width: 110,
            field: col,
            cellStyle:(params)=>{
              if (params.value === 'paid') {
                return {
                  color: '#125d12',
                  'background-color': '#2eca2e',
                  'font-size': 'medium',
                  'text-align': 'center'
                }
                // return `<div style="background-color: #2eca2e; color: green; font-size: medium; text-align: center;"><bold>Paid</bold></div>`
              } else /*if (params.value === 'unpaid') {*/
                return {
                  color: '#710000',
                  'background-color': '#d48590',
                  'font-size': 'medium',
                  'text-align': 'center'
                }
                // return `<div style="background-color: #d48590; color: red; font-size: medium; text-align: center;"><bold>Unpaid</bold></div>`
              // }
              // else return params.value;
            }
          })
        })
        this.setState({ entries: _rows, columnDefs: def })
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        this.setState({ loading: false })
      })
  }

  _forgetValue = () => {
    this.setState({
      balValue: null
    });
  };

  _rememberValue = balValue => {
    this.setState({ balValue });
  };

  renderSharingActions() {
    return (
      <Grid container style={{ margin: "2px" }}>
        <Grid item md={12} sm={12} xs={12}>
          {true && <Button disabled={!true}
            onClick={() => this.handleReloadDashboard()}
            variant="contained"
            size="small"
            color="default">
            <i className="pe-7s-refresh"></i> Refresh </Button>}
        {/* &nbsp;
        {this.state.enableEditContributor && <Button disabled={!this.state.enableEditContributor}
            onClick={() => this.handleOpenModal("contributor-edit")}
            variant="contained"
            size="small"
            color="primary">PAID</Button>}
        &nbsp;
        {this.state.enableDeleteContributor && <Button disabled={!this.state.enableDeleteContributor}
            onClick={() => this.handleOpenModal("contributor-delete")}
            variant="contained"
            size="small"
            color="secondary">UNPAID</Button>}
        &nbsp; */}
        </Grid>
      </Grid>
    )
  }

  render() {
    return (
          <div className='ag-theme-balham' style={{ height: '500px', padding: '6px' }}>
            {/* {this.renderSharingActions()} */}
            <AgGridReact
              gridOptions={{
                defaultColDef: {
                  resizable: true
                }
              }}
              onGridReady={params => params.api.sizeColumnsToFit()}
              columnDefs={this.state.columnDefs}
              className="my-custom-header"
              rowData={this.state.entries}
              rowSelection="none"
              animateRows
            />
          </div>
    );
  }
}

ChitPayDashboard.propTypes = {
  title: PropTypes.string
};

export default ChitPayDashboard