import React from 'react'
import PropTypes from 'prop-types'
import * as csv from "csvtojson";
import Constants from '../../utils/constants';

const CSVParser = ({
  accept = '.csv',
  cssClass = 'csv-reader-input',
  cssInputClass = 'csv-input',
  fileEncoding = 'UTF-8',
  inputId = 'csvUpload',
  inputStyle = {},
  label,
  onError,
  onFileLoaded,
  headerPresent
}) => {
  const handleChangeCSVFile = async e => {
    let reader = new FileReader()
    const filename = e.target.files[0].name
    reader.onload = async event => {
      const bufferCSV = event.target.result;
      let header = bufferCSV.trim().split('\n')[0];
      
      // Checking duplicate columns
      let columns = header.split(',');
      if (Constants.isArrayElementsDuplicated(columns)) {
        onError(`Header row [${columns.join('|')}] contains duplicate keys. Please make sure header keys are unique.`);
      } else {
        let config = {
            ignoreEmpty: false,
            checkType: true,
            flatKeys: true,
            nullObject: true,
            checkColumn: false,
            maxRowLength: 999,
            //Alway include header; if not present defaults to field1, field2...
            noheader: true
        };
        csv(config)
        .fromString(bufferCSV)
        .then((records) => {
            /**
             * Convert from:
             * [{
             *  "field1": "01/01/2010",
             *  "field2": "Sample desc",
             *  "field3": "10.00"
             * }]
             * 
             * to:
             * [{
             *  0 : "01/01/2010",
             *  1 : "Sample desc",
             *  2 : "10.00"
             * }]
             * 
             */
            let _normalized = records.reduce((arr, record, i)=>{
              arr.push(Object.values(record).reduce((o, val, j)=>{
                o[j] = val;
                return o;
              }, {}));
              return arr;
            }, []);
            onFileLoaded(_normalized, filename);
        })
        .catch((err)=>{
          onError(err.message)
        })

      }
    }
    reader.readAsText(e.target.files[0], fileEncoding)
  }

  return (
    <div className={cssClass}>
      {label && <label htmlFor={inputId}>{label}</label>}
      <input
        className={cssInputClass}
        type="file"
        id={inputId}
        style={inputStyle}
        accept={accept}
        onChange={e => handleChangeCSVFile(e)}
      />
    </div>
  )
}

CSVParser.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onError: PropTypes.func, 
  onFileLoaded: PropTypes.func.isRequired,
  headerPresent: PropTypes.bool.isRequired
}

export default CSVParser
