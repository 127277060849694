import React from 'react'
import moment from 'moment';
import {/*  object,  */string, func, element, oneOfType } from 'prop-types'

const OUT_FORAT = "YYYY-MM-DD";
const US_DATE_FORMATS = ['MM/DD/YYYY', 'MM-DD-YYYYHH:mm:ss', 'MM-DD-YYYY', 'MM-DD-YY'];
const UK_DATE_FORMATS = ['DD-MM-YYYYHH:mm:ss', 'DD-MM-YYYY', 'DD-MM-YY'];

function parseDate(dateStr, formats) {
  if (formats === 'us') {
    formats = US_DATE_FORMATS;
  }
  if (!formats) {
    formats = UK_DATE_FORMATS;
  }
  formats = [].concat(formats);

  let str = dateStr.replace(/ /g, '');
  str = str.replace(/\//g, '-');
  str = str.replace(/'/g, '-');
  str = str.replace(/(^|[^0-9])([0-9])([^0-9]|$)/g, '$10$2$3');
  // debug(`input date ${dateStr} became ${str}`);

  while (formats.length) {
    const format = formats.shift();
    let formatted = null;
    try {
      formatted = moment(str, format).format(OUT_FORAT);
    } catch (error) {}
    if (formatted && !formatted.includes('Invalid')) {
      // debug(`input date ${str} parses correctly with ${format}`);
      // return fecha.format(formatted, 'YYYY-MM-DDTHH:mm:ss');
      return formatted;
    }
  }

  return `<invalid date:"${dateStr}">`;
}


// exports.parseInput = function parseInput(qifData, options, callback) {
//   const { encoding } = jschardet.detect(qifData);
//   let err;

//   if (!callback) {
//     callback = options;
//     options = {};
//   }

//   if (encoding.toUpperCase() !== 'UTF-8' && encoding.toUpperCase() !== 'ASCII') {
//     qifData = Iconv.decode(Buffer.from(qifData), encoding);
//   } else {
//     qifData = qifData.toString('utf8');
//   }

//   try {
//     qifData = exports.parse(qifData, options);
//   } catch (e) {
//     err = e;
//   }

//   callback(err || undefined, qifData);
// };

// exports.parseStream = function parseStream(stream, options, callback) {
//   let qifData = '';
//   if (!callback) {
//     callback = options;
//     options = {};
//   }
//   stream.on('data', (chunk) => {
//     qifData += chunk;
//   });
//   stream.on('end', () => {
//     exports.parseInput(qifData, options, callback);
//   });
// };

// exports.parseFile = function parseFile(qifFile, options, callback) {
//   if (!callback) {
//     callback = options;
//     options = {};
//   }
//   fs.readFile(qifFile, (err, qifData) => {
//     if (err) {
//       return callback(err);
//     }
//     return exports.parseInput(qifData, options, callback);
//   });
// };



function parse(qif, options) {
  /* eslint no-multi-assign: "off", no-param-reassign: "off", no-cond-assign: "off",
      no-continue: "off", prefer-destructuring: "off", no-case-declarations: "off" */
  const lines = qif.split('\n');
  let line;// = lines.shift();
  // console.log('line', line);
  // const type = /!Type:([^$]*)$/.exec(line.trim());
  const data = {};
  const transactions = data.transactions = [];
  let transaction = {};

  options = options || {};

  // if (!type || !type.length) {
  //   throw new Error(`File does not appear to be a valid qif file: ${line}`);
  // }
  // data.type = type[1];

  let division = {};

  while (line = lines.shift()) {
    line = line.trim();
    if (line === '') continue;
    if (line === '^') {
      transactions.push(transaction);
      transaction = {};
      continue;
    }
    let desc = line.substring(1);
    if (desc) desc = desc.trim();
    switch (line[0]) {
      case 'D':
        transaction.date = parseDate(desc, options.dateFormat);
        break;
      case 'T':
        transaction.amount = parseFloat(desc.replace(',', '').replace(' ', ''));
        break;
      case 'U':
        // Looks like a legacy repeat of 'T'
        break;
      case 'N':
        transaction.number = desc;
        break;
      case 'M':
        transaction.memo = desc;
        break;
      case 'A':
        transaction.address = (transaction.address || []).concat(desc);
        break;
      case 'P':
        transaction.payee = desc.replace(/&amp;/g, '&');
        break;
      case 'L':
        const lArray = desc.split(':');
        transaction.category = lArray[0];
        if (lArray[1] !== undefined) {
          transaction.subcategory = lArray[1];
        }
        break;
      case 'C':
        transaction.clearedStatus = desc;
        break;
      case 'S':
        const sArray = desc.split(':');
        division.category = sArray[0];
        if (sArray[1] !== undefined) {
          division.subcategory = sArray[1];
        }
        break;
      case 'E':
        division.description = desc;
        break;
      case '$':
        division.amount = parseFloat(desc);
        if (!(transaction.division instanceof Array)) {
          transaction.division = [];
        }
        transaction.division.push(division);
        division = {};

        break;
      case '!':
        data.type = desc.substring(4);
        break;

      default:
        throw new Error(`Unknown Detail Code: ${line[0]}`);
    }
  }

  if (Object.keys(transaction).length) {
    transactions.push(transaction);
  }

  return data;
};

const QIFParser = ({
  accept = '.qif',
  cssClass = 'csv-reader-input',
  cssInputClass = 'csv-input',
  fileEncoding = 'UTF-8',
  inputId = null,
  inputStyle = {},
  // transOnly,
  label,
  onError,
  onFileLoaded,
  /* parserOptions = {}, */
}) => {
  const handleChangeFile = e => {
    let reader = new FileReader()
    if (e.target.files.length > 0) {
      // const filename = e.target.files[0].name

      reader.onload = async event => {
        const buffer = event.target.result;
        console.log(buffer);
        var options = {
            dateFormat: 'us'
        }
        let qifJson = undefined;
        try {
            qifJson = parse(buffer, options);    
            console.log('qifJson', qifJson);
            onFileLoaded(qifJson);
        } catch (error) {
            onError(error.message)
        }       
      }

      reader.readAsText(e.target.files[0], fileEncoding)
    }
  }

  return (
    <div className={cssClass}>
      {label && <label htmlFor={inputId}>{label}</label>}
      <input
        className={cssInputClass}
        type="file"
        id={inputId}
        style={inputStyle}
        accept={accept}
        onChange={e => handleChangeFile(e)}
      />
    </div>
  )
}

QIFParser.propTypes = {
  label: oneOfType([string, element]),
  onError: func, 
  onFileLoaded: func.isRequired,
}

export default QIFParser
