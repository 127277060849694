import React from 'react';
import { Route, Redirect } from 'react-router-dom';
// import AuthorizedRoute from './AuthorizedRoute';
import {isAuthenticated} from './Session';
function ProtectedRoute({ component: Component, ...rest }) {
    // console.log('protected route:' + rest.path);
    return (
        <Route
        {...rest}
        render={props =>
            isAuthenticated("ProtectedRoute") ? (
                // If user is not authorized, do not show listitems page
                // TODO: Why not /admin check?
                // (rest.path === '/listitems' || rest.path === '/admin') ? (
                //     <AuthorizedRoute component={Component} />
                // ) : (
                    <Component {...props} />
                // )
            ) : (
                // props.history.push('/login')
            <Redirect
                to={{
                pathname: "/auth/login",
                state: { from: props.location }
                }}
            />
            )
        }
        />
    );
}
export default ProtectedRoute;