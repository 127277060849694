import React from "react";
import PropTypes from 'prop-types';
import './AccountCard.css';
import './AccountCardMobile.css';
import Constants from "../utils/constants";
import ListItemHandler from "../api/ListItemHandler";

class AccountCard extends React.Component {

    constructor(props) {
      super(props)
      this._handlerListItem = new ListItemHandler(this);
    }
    
	state = {
		typeMap: []
    };
    
    componentDidMount = () => {
        this.handleGetAcctTypes();
    }

    handleGetAcctTypes = async () => {
        let typeMap = [];
        this._handlerListItem.getAcctTypes()
		.then((data) => {
            // console.log(data);
            data.forEach((type, index) => {
                typeMap[type.code] = type.name;
            });
            this.setState({ typeMap });
		})
		.catch((err) => {
			console.log('error while fetching account types:' + err.message);
        });
    }
    
    render() {
        let _this = this;
        let _imgPath = '/assets/img/accounts/icons';
        let _accountTypes = ["CC","CHQ","DEF","FRN","SAV", "BILL", "INV", "CHIT", "SUB", "UTIL"];
        return (
            <ul className={`cardGrid ${_this.props.zoomIn  ? 'zoomin' : ''}`}>
                {this.props.accounts.map(function (account, index) {
                    let icon = <img src={`${_imgPath}/DEF.png`} alt="Default Account" width={100}  />;
                    if (_accountTypes.includes(account.type)) {
                        icon = <img src={`${_imgPath}/${account.type}.png`} alt={account.type} width={100} />;
                    }
                    let locale = account.curr === 'INR' ? 'en-IN' : 'en-US';
                    let bal = Number(account.bal);
                    let creditBal = '';
                    if (account.type === 'CC') {
                        if (account.bal <= 0) {
                            creditBal = 'credit-bal';
                            if (account.bal < 0) bal *= -1;
                        }
                    }
                    bal = bal.toLocaleString(locale, { style: 'currency', currency: account.curr });
                    if (creditBal === 'credit-bal' && account.bal < 0) {
                        bal += ' CR'
                    }
                    let zoomInClass = '';
                    if (_this.props.zoomIn) {
                        zoomInClass = (!account.isOwner ? 'shared-account-card' : 'owner-account-card');
                    }
                    zoomInClass += (account.status === 'ac') ? ' active-account' : ' archive-account'
                    return (
                        <li key={account._id} className={`${account.type} card ${zoomInClass}`}>
                            {icon}
                            <span className="code">{account.code}</span>
                            {!account.isOwner && 
                                <span className="shared-icon">
                                    <i className="pe-7s-users" style={{fontSize: 34}}></i>
                                </span>}
                            {_this.props.showEditIcon && 
                                <span className="action-edit" onClick={()=>_this.props.onEdit(account._id)}>
                                    <i className="pe-7s-pen" style={{fontSize: 34}}></i>
                                    <span>Edit</span>
                                </span>}
                            {_this.props.showViewTransIcon && account.status === 'ac' && 
                                <span className="action-view" onClick={()=>_this.props.onViewTrans(account._id)}>
                                    <i className="pe-7s-cash" style={{fontSize: 50}}></i>
                                    <span>Transactions</span>
                                </span>}
                            <span className="name">{Constants.getAccountName(account)}</span>
                            <span className="type">{_this.state.typeMap[account.type]}</span>
                            <span className={`bal ${creditBal}`}>{bal}</span>
                        </li>
                    )
                })}
            </ul>
        )
    }
}

AccountCard.propTypes = {
    accounts: PropTypes.array.isRequired,
    onEdit: PropTypes.func.isRequired,
    onViewTrans: PropTypes.func.isRequired,
    onUnlock: PropTypes.func,
    showEditIcon: PropTypes.bool,
    showViewTransIcon: PropTypes.bool,
    zoomIn: PropTypes.bool
}

export default AccountCard;