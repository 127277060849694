import { Dialog } from '@material-ui/core';
import React from 'react';
import Constants from '../utils/constants';

class Footer extends React.Component {
    
    state = {
        navItems: [],
        openAttributes: false,
        version: process.env.REACT_APP_VERSION
    }

    componentDidMount() {
        // console.log('sidebar did mount');
        let path = window.location.pathname.toLowerCase();
        // console.log('path:' + path);
        let navItems = [];
        Constants.SIDEBAR_ITEM_LIST.forEach((item)=>{
            item.active = (item.path !== '/' && path.startsWith(item.path)) || (path === item.path);
            navItems.push(
                <li key={item.name}>
                    <a href={item.path}>
                        {item.name}
                    </a>
                </li>
            );
        })
        this.setState({ 
            navItems: navItems,
        });
        
    }

    handleCloseModal() {
        this.setState({ openAttributes: false })
    }
    renderAttributionsDialog() {
        return (
            <Dialog open={this.state.openAttributes}
                fullWidth={true}
                maxWidth="md"
                hideBackdrop={true}
                style={{modal: { padding: 0 }}}
				onClose={(e, reason)=>{
					if (reason === "backdropClick") this.handleCloseModal()
				}}
                PaperProps={{ style: { backgroundColor: Constants.COLORs.VANKAYA_250 }}}>
                <h3>Attributions</h3>
                <ul>
                    <li><a href="https://www.vecteezy.com/free-vector/group-of-people-holding-hands" target="_blank" rel="noopener noreferrer">Group Of People Holding Hands Vectors by Vecteezy</a></li>
                </ul>
            </Dialog>
        )
    }


    render() {
        return (
            <footer className="footer">
                <div className="container-fluid">
                    <nav>
                        <ul className="footer-menu">
                            {this.state.navItems}
                            {/* {Constants.SIDEBAR_ITEM_LIST.forEach((item)=>{
                                return <li><a href={`${item.path}`}>{item.name}</a></li>
                            })} */}
                            {/* <li>
                                <a href="/">
                                    Home
                                </a>
                            </li>
                            <li>
                                <a href="/">
                                    Accounts
                                </a>
                            </li>
                            <li>
                                <a href="/">
                                    Portfolio
                                </a>
                            </li>
                            <li>
                                <a href="/">
                                    Blog
                                </a>
                            </li> */}
                        </ul>
                        <p className="copyright text-center">
                            ©{new Date().getFullYear()}&nbsp;
                            Codecubers.com
                        </p>
                        <p className="attributes">build: {this.state.version}&nbsp;&nbsp;Theme: <a href="http://www.creative-tim.com">Creative Tim</a></p>
                        {/* <p className="enviroment" onClick={()=>this.setState({openAttributes: false})}></p> */}
                        {this.state.openAttributes && this.renderAttributionsDialog()}
                    </nav>
                </div>
            </footer>
        )
    }
}

export default Footer;