import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cloneDeep as _cloneDeep } from 'lodash';
import { withSnackbar } from 'notistack';
import moment from 'moment-timezone';

import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography, TextField, LinearProgress, FormControl, Dialog, InputLabel } from '@material-ui/core';

import './Tran.css'
import { validator } from '../utils/validator';
import {TranStyle} from './TranStyle';
import { tranObject } from './tranObject';
import { getInstance } from '../utils/axiosLoader';
import Constants from '../utils/constants';
import mapStateToProps from '../actions/stateToProps';
import { WebClientStore } from 'web-client-store';
let {Session} = WebClientStore;
let {SESSION_KEYS} = Constants;

let classes = null;

function cloneTransactions(trans=[], fromAc='unknown') {
    return trans.reduce((arr, tran)=>{
        let isDebit = true;
        let tranToLink = _cloneDeep(tranObject);
        if (tran._id) tranToLink._id = tran._id;
        if (tran.sno) tranToLink.sno.value = tran.sno;
        if (tran.dr) tranToLink.cr.value = tran.dr;
        if (tran.cr) {
            tranToLink.dr.value = tran.cr;
            if(tran.cr > 0) isDebit = false;
        }
        if (tran.bal) tranToLink.bal.value = tran.bal;
        if (tran.curr) tranToLink.curr.value = tran.curr;
        if (tran.type) {
            let type;
            switch(tran.type) {
                case 'loan_in': type = 'loan_out'; break;
                case 'loan_out': type = 'loan_in'; break;
                case 'remit_in': type = 'remit_out'; break;
                case 'remit_out': type = 'remit_in'; break;
                case 'credit_in': type = 'credit_out'; break;
                case 'credit_out': type = 'credit_in'; break;
                case 'transfer_in': type = 'transfer_out'; break;
                case 'transfer_out': type = 'transfer_in'; break;
                default: type = 'unknown';
            }
            tranToLink.type.value = type;
        }
        if (tran.qtr) tranToLink.qtr.value = tran.qtr;
        if (tran.account) tranToLink.account.value = tran.account;
        if (tran.date) {
            // tranToLink.date.value = moment(tran.date).format('YYYY-MM-DD');
            tranToLink.date.value = tran.date.substr(0,10)
        }
        if (tran.desc) tranToLink.note.value = 'desc:' + tran.desc + ' ';
        if (tran.note) tranToLink.note.value += 'note:' + tran.note;
        
        tranToLink.desc.value = 'Linked ' + (isDebit ? 'to:' : 'from:') + fromAc.name;

        arr.push(tranToLink);
        return arr;
    }, []);
}

class LinkTran extends Component {
	constructor(props) {
		super(props)
		classes = this.props.classes;
		this.handleFieldValidation = this.handleFieldValidation.bind(this)
		this.handleFieldLinkAccountChange = this.handleFieldLinkAccountChange.bind(this)
	}

	state = {
		actionInProgress      : {
			openLinearLoading   : false,
			disableActionButton : false,
			disableLinkAction: true
		},
		sqlErrorMessage: '',
        transaction          : _cloneDeep(tranObject),
		mode: this.props.mode,
        linkAccountId: 'unknown',
        accounts: [],
        transactions: []
	};

	componentDidMount() {
        let updates = {};

        let lastAc = Session.get(SESSION_KEYS.LAST_LINK_ACCOUNT);
		if (lastAc) {
			let _actionInProgress = this.state.actionInProgress;
            _actionInProgress.disableLinkAction = false;
            updates.linkAccountId = lastAc;
            updates.actionInProgress = _actionInProgress;
        }

        let accounts = this.props.accountLineUp;
        let trans = this.props.transactions;
        let fromAc;
        let active = Session.get(SESSION_KEYS.ACTIVE_ACCOUNT);
        if (active !== undefined && active !== '') {
            fromAc = accounts.filter((item) => {
                return item._id === active; 
            })[0];
        }
        if (!fromAc) {
            this.props.onError('Please select an account before linking a transaction');
            return;
        }        
        
        //Allow link between accounts with same current for now; excluding current account
        let linkEligibleAccounts = accounts.filter((item) => {
            return item.isOwner && (
                (item.curr === fromAc.curr && item._id !== fromAc._id) || item._id === 'unknown' 
            ); 
        });
        updates.accounts = linkEligibleAccounts;

        let transToLink = cloneTransactions(trans, fromAc);
        updates.transactions = transToLink;
        updates.transaction = transToLink[0];
        
        this.setState(updates);
	}

	handleFieldValidation(e) {
		let _transaction = this.state.transaction;
		let _field = _transaction[e.target.name];
		let rules = Object.keys(_field.rules);

		_field.value = e.target.value;

		if (rules) {
			for (let rule of rules) {
				if (_field.rules[rule] && rule in validator) {
					let validation = validator[rule](_field.value, _field.rules[rule]);

					if (!validation.passed) {
						_field.hasErrors.error = true;
						_field.hasErrors.message = validation.message;

						break;
					}
					else {
						_field.hasErrors.error = false;
						_field.hasErrors.message = validation.message;
					}
				}
			}
		}

		this.setState({
			transaction : _transaction
		});
	};

	handleFieldLinkAccountChange(e) {
		let _actionInProgress = this.state.actionInProgress;
		_actionInProgress.disableLinkAction = (e.target.value === 'unknown')
		this.setState({
			linkAccountId: e.target.value,
			actionInProgress: _actionInProgress
		});
	}

	prepareReleaseObjectForSaving = (_transaction = null) => {
		let preparingReleaseObject = {};
		let _keys = Object.keys(_transaction);
		for (let key of _keys) {
			if (key !== '_id') { 
				preparingReleaseObject[key] = _transaction[key].value;
			}
		}
		//Currently only same currency account is possible;
		//TODO: ability to chose a different currency account & do currency conversion
		preparingReleaseObject.curr = this.props.account.curr;
		//Current transaction id becomes 'link' transaction
		preparingReleaseObject.link = _transaction._id;
		//Transfer the transaction to the new account
		preparingReleaseObject.account = this.state.linkAccountId;
		//reset transaction id
		delete preparingReleaseObject._id;
		
		// if (_transaction._id) preparingReleaseObject._id = _transaction._id;
		return preparingReleaseObject;
	};

	handleLinkTransaction = async () => {
		let _transaction = this.state.transaction;
		console.log('transaction before link process:', _transaction);
		/* TODO: make this error disable the submit button in 'FieldsErrorFree' logic similar to new list item. */
		if (this.props.account.code === 'unknown') {
			this.props.enqueueSnackbar('Selected Account is invalid. Transaction cannot be linked.', {
				variant : 'error'
			});
			return;
		}
		let _actionInProgress = this.state.actionInProgress;
		_actionInProgress.disableActionButton = true;
		_actionInProgress.openLinearLoading = true;
		this.setState({
			actionInProgress : _actionInProgress
		});
		
		let preparedReleaseObject = this.prepareReleaseObjectForSaving(_transaction);
		if (preparedReleaseObject.account === 'unknown') {
			this.props.enqueueSnackbar('Selected Link Account is invalid. Transaction cannot be linked.', {
				variant : 'error'
			});
			return;
		}
		Session.set(SESSION_KEYS.LAST_LINK_ACCOUNT, preparedReleaseObject.account);
		let responseErr = '';
		// console.log('sending link request to:' + `${Constants.URLs.TRANS}/${_transaction._id}`, preparedReleaseObject)
		await getInstance()
			.post(`${Constants.URLs.TRANS}/link/${_transaction._id}`, preparedReleaseObject)
			.then(async (response) => {
				if (response.status === 400) {
					console.log(response)
					this.props.enqueueSnackbar('Error: An internal error occured while linking transaction.', {
						variant : 'error'
					});
					responseErr = response.data.message
				}
				else {
					this.props.enqueueSnackbar('Transaction linked successfully', {
						variant : 'success'
					});
					//set the target account to refresh on next visit
					Session.set(preparedReleaseObject.account + '_req_ts', moment().format('YYYYMMDDhhmmss'));
					_transaction = _cloneDeep(tranObject)
				}
			})
			.catch((error) => {
				console.log(error);
				responseErr = error.message;
				this.props.enqueueSnackbar('An error occurred from the server', {
					variant : 'error'
				});
			})
			.finally(()=>{
				_actionInProgress.disableActionButton = false;
				_actionInProgress.openLinearLoading = false;
				this.setState({
					sqlErrorMessage  : responseErr,
					transaction: _transaction,
					actionInProgress : _actionInProgress
				});
				if (responseErr === '') {
					this.props.onSubmit();
				}
			})
	}

	
	handleLinkMultiTransactions = async () => {
		let _transactions = this.state.transactions;
		/* TODO: make this error disable the submit button in 'FieldsErrorFree' logic similar to new list item. */
		if (this.props.account.code === 'unknown') {
			this.props.enqueueSnackbar('Selected Account is invalid. Transaction cannot be linked.', {
				variant : 'error'
			});
			return;
		}
		let _actionInProgress = this.state.actionInProgress;
		_actionInProgress.disableActionButton = true;
		_actionInProgress.openLinearLoading = true;
		this.setState({
			actionInProgress : _actionInProgress
		});
		
		let _processed = _transactions.reduce((arr, _transaction)=>{
			arr.push(this.prepareReleaseObjectForSaving(_transaction))
			return arr;
		},[]);
		
		console.log('bulk transactions before link process:', _processed);
		// let preparedReleaseObject = this.prepareReleaseObjectForSaving(_transactions);
		
		Session.set(SESSION_KEYS.LAST_LINK_ACCOUNT, _processed[0].account);
		let responseErr = '';
		// console.log('sending link request to:' + `${Constants.URLs.TRANS}/${_transaction._id}`, preparedReleaseObject)
		await getInstance()
			.post(`${Constants.URLs.TRANS}/bulklink/ac/${this.state.linkAccountId}`, _processed)
			.then(async (response) => {
				if (response.status === 400) {
					console.log(response)
					this.props.enqueueSnackbar('Error: An internal error occured while linking transaction.', {
						variant : 'error'
					});
					responseErr = response.data.message
				}
				else {
					this.props.enqueueSnackbar('Transaction linked successfully', {
						variant : 'success'
					});
					//set the target account to refresh on next visit
					Session.set(_processed[0].account + '_req_ts', moment().format('YYYYMMDDhhmmss'));
					// _transaction = _cloneDeep(tranObject)
				}
			})
			.catch((error) => {
                console.log(error);
                let msg = 'Error: Unable to bulk link the transactions at this point.';
                try {
                    if (error.response.data.message) msg = error.response.data.message;
                } catch (error) {}
				responseErr = msg;
				this.props.enqueueSnackbar(msg, {
					variant : 'error'
				});
			})
			.finally(()=>{
				_actionInProgress.disableActionButton = false;
				_actionInProgress.openLinearLoading = false;
				this.setState({
					sqlErrorMessage  : responseErr,
					// transaction: _transaction,
					actionInProgress : _actionInProgress
				});
				if (responseErr === '') {
					this.props.onSubmit();
				}
			})
	}



	/**
	 * Render UI components
	 */

	renderFormTitle() {
		let label = "Link ";
		label += this.props.isCredit ? 'credit ' : 'debit ';
		label += ' transaction in';
		return (
			<Grid item md={12} lg={12} xs={12} style={{marginBottom: "5px"}}>
				<Typography align="center">
					{label}
				</Typography>
				<Typography variant="h5" align="center">
					{this.props.account.name}
				</Typography>
			</Grid>
		)
	}

	renderFormFieldAmnt() {
		let field = this.props.isCredit ? this.state.transaction.cr : this.state.transaction.dr;
		return (
			<Grid item md={12} lg={12} xs={12}>
				<TextField
					name={field.id}
					label={field.alias}
					className={classes.textField}
					margin="normal"
					value={field.value}
					disabled={true}
					error={field.hasErrors.error}
					required={true}
					onChange={this.handleFieldValidation}
					variant="standard" // opt: standard, outlined or filled
					helperText={field.hasErrors.message}
				/>
			</Grid>
		)
	}

	renderFormFieldType() {
		return (
			<Grid item md={12} lg={12} xs={12} style={{ marginTop: "10px"}}>
				<FormControl className={classes.tranAccountSelect}>
				<InputLabel>Transaction Type:</InputLabel>
				<Select
					label='Transaction Type:'
					name={'type'}
					value={this.state.transaction.type.value}
					onChange={this.handleFieldValidation}>
					{this.props.tranTypes.map((type, index) => {
						return (
							<MenuItem
								key={`type-${type.code}`}
								value={type.code}>
								{`${type.name}`}
							</MenuItem>
						);
					})}
				</Select>
				</FormControl>
			</Grid>
		)
	}

	renderFormLinkAccount() {
		// let last = Session.get(SESSION_KEYS.LAST_LINK_ACCOUNT);
		// if (!last) {
		// 	last = 'unknown';
		// }
		return (
			<Grid item md={6} sm={6} xs={6}>
				<div className={classes.tranAccount}>
					<InputLabel>Select Link Account:</InputLabel>
					<Select
						name="select-link-account"
						value={this.state.linkAccountId}
						onChange={this.handleFieldLinkAccountChange}
						className={'trans-account-select-width'}>
						{this.state.accounts.map((account, index) => {
							return (
								<MenuItem
									key={`account-${account._id}`}
									value={account._id}>
									{`${account.name}`}	
								</MenuItem>
							);
						})}
					</Select>
				</div>
			</Grid>
		)
	}

	renderFormFieldDate() {
		return (
			<Grid item md={12} lg={12} xs={12}>
				<TextField
					name={'date'}
					label={this.state.transaction.date.alias}
					className={classes.textField}
					margin="normal"
					type="date"
					value={this.state.transaction.date.value}
					error={this.state.transaction.date.hasErrors.error}
					required={this.state.transaction.date.rules.required}
					onChange={this.handleFieldValidation}
					variant="standard" // opt: standard, outlined or filled
					helperText={this.state.transaction.date.hasErrors.message}
				/>
			</Grid>
		)
	}

	renderFormFieldDesc() {
		return (
			<Grid item md={12} lg={12} xs={12}>
				<TextField
					name={'desc'}
					label={this.state.transaction.desc.alias}
					className={classes.textField}
					margin="normal"
					value={this.state.transaction.desc.value}
					error={this.state.transaction.desc.hasErrors.error}
					required={this.state.transaction.desc.rules.required}
					onChange={this.handleFieldValidation}
					variant="standard" // opt: standard, outlined or filled
					helperText={this.state.transaction.desc.hasErrors.message}
				/>
			</Grid>
		)
	}

	renderFormFieldNote() {
		return (
			<Grid item md={12} lg={12} xs={12}>
				<TextField
					name={'note'}
					label={this.state.transaction.note.alias}
					className={classes.textField}
					margin="normal"
					disabled={true}
					value={this.state.transaction.note.value}
					error={this.state.transaction.note.hasErrors.error}
					required={this.state.transaction.note.rules.required}
					onChange={this.handleFieldValidation}
					variant="standard" // opt: standard, outlined or filled
					helperText={this.state.transaction.note.hasErrors.message}
				/>
			</Grid>
		)
	}

	renderSQLError() {
		return (
			<Grid item md={12} lg={12} xs={12}>
				<Typography 
					variant="caption" 
					align="center" 
					className={classes.sqlErrorMessage}>
					{this.state.sqlErrorMessage}
				</Typography>
			</Grid>
		)
	}

	renderActions() {
		return (
			<div className={classes.newAccountModalActions}>
				<Button
					variant="contained"
					color="primary"
					className={classes.button}
					onClick={() => this.props.onCancel()}>
					Cancel
				</Button>
				{this.props.mode === 'single' && <Button
					variant="contained"
					color="secondary"
					className={classes.button}
					disabled={this.state.actionInProgress.disableLinkAction}
					onClick={this.handleLinkTransaction}>
					Link
				</Button>}
				{this.props.mode === 'multiple' && <Button
					variant="contained"
					color="secondary"
					className={classes.button}
					disabled={this.state.actionInProgress.disableLinkAction}
					onClick={this.handleLinkMultiTransactions}>
					Bulk Link
				</Button>}
			</div>
		)
	}

	renderLinearLoader() {
		return (
			this.state.actionInProgress.openLinearLoading &&
				<LinearProgress color="primary" variant="query" />
		)
	}

	renderSingleLinkForm() {
		return (
			<Dialog
					open={this.props.display}
					onClose={() => {}}
					styles={{ modal: { padding: 0 } }} >
					<Grid container className={classes.newAccountModal}>
							{this.renderFormTitle()}
							{this.renderFormLinkAccount()}
							{this.renderFormFieldType()}
							{this.renderFormFieldAmnt()}
							{this.renderFormFieldDate()}
							{this.renderFormFieldDesc()}
							{this.renderFormFieldNote()}
							{this.renderSQLError()}
							</Grid>
								<Grid container className={classes.newAccountModalActions}>
								{this.renderActions()}
								{this.renderLinearLoader()}
							</Grid>
			</Dialog>
		);
	}

	renderMultiLinkForm() {
		return (
			<Dialog
					open={this.props.display}
					onClose={() => {}}
					styles={{ modal: { padding: 0 } }} >
					<Grid container className={classes.newAccountModal}>
							{this.renderFormTitle()}
							{this.renderFormLinkAccount()}
							{/* {this.renderFormFieldType()}
							{this.renderFormFieldAmnt()}
							{this.renderFormFieldDate()}
							{this.renderFormFieldDesc()}
							{this.renderFormFieldNote()}
							{this.renderSQLError()} */}
							</Grid>
								<Grid container className={classes.newAccountModalActions}>
								{this.renderActions()}
								{this.renderLinearLoader()}
							</Grid>
			</Dialog>
		);
	}

	render() {
		if (this.props.mode === 'single') {
			return this.renderSingleLinkForm();
		} else {
			return this.renderMultiLinkForm();
		}
	}
}

LinkTran.propTypes = {
	classes : PropTypes.object.isRequired,
    display: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	onError: PropTypes.func.isRequired,
	tranTypes: PropTypes.array.isRequired,
	account: PropTypes.object.isRequired,
	isCredit: PropTypes.bool.isRequired,
	//Needed for update transaction
	mode: PropTypes.string.isRequired,
	accountLineUp: PropTypes.array.isRequired,
	transactions: PropTypes.array.isRequired,
};

export default connect(mapStateToProps)(withSnackbar(withStyles(TranStyle)(LinkTran)));
