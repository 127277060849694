import React from 'react';
import PropTypes from 'prop-types';
import '../../../node_modules/react-vis/dist/style.css';
import './widgetstyles.css';
import {
  XYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalBarSeries,
  DiscreteColorLegend,
  LineSeries,
  Hint,
  MarkSeries
} from 'react-vis';
import Constants from '../utils/constants';
import LoadingOverlay from '../common/loadingOverlay';
import { WidgetHandler } from '../api';
import { WebClientStore } from 'web-client-store';
import { Checkbox } from '@material-ui/core';
let {Session} = WebClientStore;
export default class YearlyTrend extends React.Component {

  constructor(props) {
      super(props);
      this._isMounted = false; // prevents -> Warning: Can't perform a React state update on an unmounted component
      this._handler = new WidgetHandler(this);
  }

  state = {
    barData: { 
      cr: [], 
      dr: [], 
      bal: [] 
    },
    activeCr: -1,
    activeDr: -1,
    stackAmounts: true,
    balValue: null,
    curr: Session.get(Constants.SESSION_KEYS.ACTIVE_ACCOUNT_CURR) || 'USD',
    openOverlay: false,
    loading: true
  };


  handleFetchData() {
    // let {clearCache} = this.props;
    this.setState({loading: true, openOverlay: true})
    this._handler.getAccountYearlyTrend(false).then((data)=>{
      this.setState({ barData: data})
    }).finally(()=>{
      this.setState({loading: false, openOverlay: false})
      if (this.props.onReloaded) {
        this.props.onReloaded();
      }
    })    
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.handleFetchData();
    }
  }

  _forgetValue = () => {
    this.setState({
      balValue: null
    });
  };

  _rememberValue = balValue => {
    this.setState({ balValue });
  };

  render() {
    const { barData, 
            balValue, 
            stackAmounts,
            loading, 
             } = this.state;
    // console.log('barData', barData);
    if (loading) {
      return <div>
        <span>Fetching records and drawing chart... please wait.</span>
      </div>;
    }
    if (!barData) return <div>
      <span>An error occurred while fetching metrix data. Please retry again later.</span>
    </div>;
    if (barData.cr.length < 1) return <div>
    <span>Not enough data to draw the chart. Please check after adding some transactions  .</span>
  </div>;
    let toolTipStyle = { background: '#C3D93A', color: 'black', fontSize: 14 };
    return (
      <div style={{ background: Constants.COLORs.VANKAYA_250, overflowX: 'auto', /*marginLeft: 'auto', marginRight: 'auto',*/ padding: 10 }}>
        <div style={{ marginLeft: 40 }}>
          <h4>{`Total Credits & Debits by Year`}</h4>
          {this.props.title && <i>{this.props.title}</i>}
        </div>
          <div>
            <span style={{marginRight: '18px', float: 'right'}}><Checkbox
              onClick={() => this.setState({ stackAmounts: !stackAmounts })}
              checked={stackAmounts} />Stack up Debits and Credits</span>
          </div>
        <XYPlot margin={{ bottom: 70, left: 100, top: 48 }}
          className="clustered-stacked-bar-chart-example"
          xType="ordinal"
          stackBy={this.state.stackAmounts ? "y" : ""}
          width={barData.cr.length * 60 + 100}
          height={500}
          getX={d => `${d.x}`}
        > 
          <DiscreteColorLegend
            style={{ position: 'absolute', left: 100, top: 2 }}
            orientation="horizontal"
            items={[
              {
                title: 'Credits',
                color: Constants.COLORs.SABBU
              },
              {
                title: 'Debits',
                color: Constants.COLORs.JAAJI
              },
              {
                title: 'Year End Balance',
                color: Constants.COLORs.KATUKA_500
              }
            ]}
          />
          <HorizontalGridLines />
          <XAxis tickLabelAngle={-45} title="Year" />
          <YAxis width={100} tickLabelAngle={0} title="Amount" />
          <VerticalBarSeries
            onValueMouseOver={(datapoint, event) => {
              let active = barData.cr.filter((c) => c.x === datapoint.x)[0];
              this.setState({ activeCr: barData.cr.indexOf(active) })
            }}
            onValueMouseOut={(datapoint, event) => {
              this.setState({ activeCr: -1 })
            }}
            color={Constants.COLORs.SABBU}
            data={barData.cr}
          />
          <VerticalBarSeries
            onValueMouseOver={(datapoint, event) => {
              let active = barData.dr.filter((c) => c.x === datapoint.x)[0];
              this.setState({ activeDr: barData.dr.indexOf(active) })
            }}
            onValueMouseOut={(datapoint, event) => {
              this.setState({ activeDr: -1 })
            }}
            color={Constants.COLORs.JAAJI}
            data={barData.dr}
          />
          {barData.cr.map((c, index) => {
            return (this.state.activeCr === index &&
              <Hint key={index} value={{ x: c.x, y: c.y / 3 }}>
                <div>
                  <span style={{ color: 'black' }}><strong>Credits:</strong></span><br />
                  <span style={toolTipStyle}>{`${Constants.toCurrencyES6(c.y, this.state.curr)}`}</span>
                </div>
              </Hint>
            )
          })}
          {barData.dr.map((d, index) => {
            return (this.state.activeDr === index &&
              <Hint key={index} value={{ x: d.x, y: d.y / 2 }}>
                <div>
                  <span style={{ color: 'black' }}><strong>Debits:</strong></span><br />
                  <span style={toolTipStyle}>{`${Constants.toCurrencyES6(d.y, this.state.curr)}`}</span>
                </div>
              </Hint>
            )
          })}
          <LineSeries
            color={Constants.COLORs.KATUKA_500}
            data={barData.bal}
          />
          <MarkSeries
            color={Constants.COLORs.KATUKA_500}
            onValueMouseOver={this._rememberValue}
            onValueMouseOut={this._forgetValue}
            data={barData.bal}
          />
          {balValue ? <Hint value={balValue}>
            <div>
              <span style={{ color: 'black' }}><strong>Balance:</strong></span><br />
              <span style={toolTipStyle}>{`${Constants.toCurrencyES6(balValue.y, this.state.curr)}`}</span>
            </div>
          </Hint> : null}
        </XYPlot>
        {this.state.openOverlay && <LoadingOverlay />}
      </div>
    );
  }
}

YearlyTrend.propTypes = {
  title: PropTypes.string,
  clearCache: PropTypes.bool,
  onReloaded: PropTypes.func
};