import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './Upload.css'
import Dropzone from './dropzone/Dropzone'
import { TranStyle } from '../tran/TranStyle';
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { withStyles, Modal, Button, Dialog } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Constants from '../utils/constants';
import { getInstance } from '../utils/axiosLoader';
import mapStateToProps from '../actions/stateToProps';
import { WebClientStore } from 'web-client-store';
let {Session} = WebClientStore;

class UploadAttach extends Component {

    constructor(props) {
        super(props)
        this.state = {
            files: [],
            uploading: false,
            uploadProgress: {},
            successfullUploaded: false,
            // csvUploadRows: [{"type":"SAV","curr":"CAD","status":"ac","_id":"5e56bc9aeec11641c4eed919","name":"HDFC Savings Account 4917","code":"HDFC_AC_4917","date":"2020-02-26T18:44:42.849Z","__v":0}],          
            csvUploadRows: [],
            ofxUploadRows: [],
            ofxUploadError: '',
            rawData: "Not parsed yet",
            canvasData: "Not parsed yet",
            trans: []
        };

        this.onFilesAdded = this.onFilesAdded.bind(this);
        this.uploadFiles = this.uploadFiles.bind(this);
        this.renderActions = this.renderActions.bind(this);
    }

    componentDidMount() {
        console.log('componentDidMount');
    }

    onFilesAdded(files) {
        let maxSize = Constants.FREE_USER_RECEIPT_UPLOAD_SIZE_LIMIT; // in Bytes
        let filtered = files.reduce((arr, file)=>{
            var filesizeInMB = ((file.size/1024)/1024).toFixed(0); // MB
            if (file.name !== "item" && typeof file.name !== "undefined" && file.size <= maxSize) { 
                arr.push(file);
            } else {
                console.log(`File is invalid or oversize (Max: ${filesizeInMB} MB).`);
                if(this.props.onError){
                    this.props.onError(`File is oversize. Please limit file size to 2MB.`)
                }
            }
            return arr;
        },[]);
        // Append files
        // this.setState(prevState => ({
        //     files: prevState.files.concat(filtered)
        // }));
        // Replace file
        this.setState({
            files: filtered
        })
    }

    async uploadFiles() {
        this.setState({ uploadProgress: {}, uploading: true });
        const promises = [];
        this.state.files.forEach(file => {
            promises.push(this.sendRequestAxios(file));
        });
        try {
            await Promise.all(promises)
                .then((response) => {
                    console.log(JSON.stringify(response, null, 2))
                    this.setState({ 
                        successfullUploaded: true, 
                        uploading: false, 
                        // rawData: lines.join('\n'), 
                        // canvasData: extract.join('\n'), 
                        // trans: trans, 
                        // canvas: true 
                    });
                })
                .catch((err) => {
                    console.log('error: ' + err.message);
                    let msg = `An error occured while uploading the receipt.`;
                    try {
                        if (err.response.data.error) msg = err.response.data.error;
                    } catch (error) {}
                    if(this.props.onError){
                        this.props.onError(msg)
                    }
                })
                .finally(()=>{
                    this.props.onSubmit();
                })
        } catch (e) {
            // Not Production ready! Do some error handling here instead...
            this.setState({ successfullUploaded: true, uploading: false });
        }
    }

    sendRequestAxios(file) {
        const _this = this;
        let { entity, ids } = _this.props;
        let id = ids[0];
        let userId = Session.get(Constants.SESSION_KEYS.USER_ID);
        return new Promise(async (resolve, reject) => {
            var formData = new FormData();
            formData.append("userId", userId);
            formData.append("entity", entity);
            formData.append("id", id);
            formData.append("file", file, file.name);

            var config = {
                headers: { 'Content-Type': 'multipart/form-data' },
                onUploadProgress: function (progressEvent) {
                    if (progressEvent.lengthComputable) {
                        const copy = { ..._this.state.uploadProgress };
                        copy[file.name] = {
                            state: "pending",
                            percentage: Math.round((progressEvent.loaded * 100) / progressEvent.total)
                        };
                        _this.setState({ uploadProgress: copy });
                    }
                },
                onUploadLoad: function (event) {
                    const copy = { ..._this.state.uploadProgress };
                    copy[file.name] = { state: "done", percentage: 100 };
                    _this.setState({ uploadProgress: copy });
                    //console.log(req.response)
                    //resolve(req.response);
                },
                onUploadError: function (event) {
                    const copy = { ..._this.state.uploadProgress };
                    copy[file.name] = { state: "error", percentage: 0 };
                    _this.setState({ uploadProgress: copy });
                    //reject(req.response);
                }
            };

            await getInstance()
                .post(`${Constants.URLs.RECEIPTS}/upload-receipt/${entity}/${id}`, formData, config)
                .then((response) => {
                    if (response.status !== 200) {
                        reject(response.data)
                    }
                    else {
                        resolve(response.data)
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }

    renderActions() {
        if (this.state.successfullUploaded) {
            return (
                <button
                    onClick={() =>
                        this.setState({ files: [], successfullUploaded: false })
                    }
                >
                    Clear
        </button>
            );
        } else {
            return (
                <button
                    disabled={this.state.files.length < 0 || this.state.uploading}
                    onClick={this.uploadFiles}
                >
                    Upload
        </button>
            );
        }
    }

    renderProgress(file) {
        const uploadProgress = this.state.uploadProgress[file.name];
        if (this.state.uploading || this.state.successfullUploaded) {
            return (
                <div className="ProgressWrapper">
                    {<progress value={uploadProgress ? uploadProgress.percentage : 0} />}
                    <img
                        className="CheckIcon"
                        alt="done"
                        src="baseline-check_circle_outline-24px.svg"
                        style={{
                            opacity:
                                uploadProgress && uploadProgress.state === "done" ? 0.5 : 0
                        }}
                    />
                </div>
            );
        }
    }

    processCSV = (data) => {
        console.log(data)
        this.setState({ csvUploadRows: data })
    }

    handleOnGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    renderGrid() {
        return (
            <div className="Upload">
                <span className="Title">Upload Files</span>
                <div className="Content">
                    <div>
                        <Dropzone
                            onFilesAdded={this.onFilesAdded}
                            disabled={this.state.uploading || this.state.successfullUploaded}
                            multiple={false}
                        />
                    </div>
                    <div className="Files" >
                        {this.state.files.map(file => {
                            return (
                                <div key={file.name} className="Row">
                                    <span className="Filename">{file.name}</span>
                                    {this.renderProgress(file)}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="Actions" >{this.renderActions()}</div>
            </div>
        )
    }

    renderContentView() {
        return (
            <div className="content">
                <div className="content-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Upload a PDF file</h4>
                                </div>
                                <div className="card-body">
                                    {this.renderGrid()}
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.trans.length > 0 && <div className="row">
                        <div className="col-md-12">
                            {this.renderProcessedGrid()}
                        </div>
                    </div>}
                </div>
            </div>
        )
    }

    renderDefaultView() {
        return (
            <div>
                <Paper elevation={4} /* className={classes.tranContainer} */>
                    <Grid container>
                        <Grid item md={10} sm={10} xs={10}>
                            {this.renderGrid()}
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        )
    }


    renderAsModal() {
        const { classes } = this.props;
        return (
            <Modal
                open={this.props.display}
                center
                showCloseIcon={false}
                closeOnOverlayClick={false}
                closeOnEsc={false}
                onClose={() => { }}
                styles={{ modal: { padding: 0, width: '300px', paddingLeft: '0.5rem' } }}>
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={() => this.props.onCancel()}>
                        Cancel
                    </Button>
                    {this.renderGrid()}
                </div>
            </Modal>
        )
    }

    renderAsDialog() {
        return (

            <Dialog
                open={this.props.display}
                // center
                // showCloseIcon={true}
                // closeOnOverlayClick={true}
                closeOnEsc={true}
                onClose={() => {}}
                // styles={{ modal: { padding: 0, height } }}
                style={{margin: '10px'}}
                >
                    <div>
                        
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => this.props.onCancel()}>
                        Cancel
                    </Button>
                    {this.renderGrid()}
                    </div>

                    
            </Dialog>
        )
    }

    render() {
        return this.renderAsDialog();
    }
}

UploadAttach.propTypes = {
    classes: PropTypes.object.isRequired,
    entity: PropTypes.string.isRequired,
    ids: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    onError: PropTypes.func
};

export default connect(mapStateToProps)(withStyles(TranStyle)(UploadAttach));
