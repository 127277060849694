import axios from 'axios';
import { getSession } from '../utils/Session';
import Navigator from '../utils/Navigator';
import Cache from '../utils/Cache';
import Constants from '../utils/constants';
import { WebClientStore } from 'web-client-store';
let {Session} = WebClientStore;

export default class 
ApiHandler {
    
    constructor(callerComponent) {
        this.comp = callerComponent;
        this.nav = new Navigator(callerComponent);
        this.cache = Cache;
    }

    getInstance = () => {
        return axios.create({ headers: { 'x-access-token': getSession() } })
    }

    showEnque = (msg, variant) => {
        if (this.comp.props.enqueueSnackbar) this.comp.props.enqueueSnackbar(msg, {variant});
    }
    showSuccess = (msg) => this.showEnque(msg, 'success');
    showInfo = (msg) => this.showEnque(msg, 'info');
    showWarn = (msg) => this.showEnque(msg, 'warning');
    showError = (msg) => this.showEnque(msg, 'error');

    handleError = (error, msg=`An error occured.`) => {
        console.log(error);
        try {
            if (error.response.data.message) msg = error.response.data.message;
        } catch (err) {}
        try {
            if (error.response.data.error) msg = error.response.data.error;
        } catch (err) {}
        this.showError(msg);
        // return new Promise.reject();
    }

    getActiveAccount = () => Session.get(Constants.SESSION_KEYS.ACTIVE_ACCOUNT);


    cachedResponse = (unset=false, key, servercall, params=[]) => {
        // console.log(`cache age of key ${key}:`, Cache.getCacheAge(key));
        let unsetCheck = Promise.resolve(unset);
        
        return new Promise((resolve, reject) => {
            return unsetCheck
            .then(condition => {
                if (condition) {
                    // console.log(`UNSETTING cache for key ${key}`)
                    return Cache.clear(key)
                }
                else return true
            })
            .then(()=>{
                return Cache.getCachedItem(key);
            })
            .then(cachedVal=>{
                // console.log(`Cached key FOUND for key ${key}`)
                resolve(cachedVal)
            })
            .catch((err)=>{
                console.warn(err)
                // console.log(`Cached value did NOT found for key ${key}; fetching from server`);
                servercall(...params)
                .then((value)=>Cache.setCacheItem(key, value))
                .then((cachedVal)=>resolve(cachedVal))
                .catch((err2)=>reject(err2))
            })
        });
    }
}