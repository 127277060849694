import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {isAuthenticated, isAuthorizedSiteAdmin} from './Session';
function AuthorizedRoute({ component: Component, ...rest }) {
    return (
        <Route
        {...rest}
        render={props =>
            isAuthenticated("AuthorizedRoute") && isAuthorizedSiteAdmin("AuthorizedRoute") ? (
                <Component {...props} />
            ) : (
            <Redirect
                to={{
                    pathname: "/dashboard"
                }}
            />
            )
        }
        />
    );
}
export default AuthorizedRoute;