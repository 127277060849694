import Constants from '../utils/constants';
import ApiHandler from './ApiHandler';
// import Constants from '../utils/constants';

export default class 
ListItemHandler extends ApiHandler {

    fetchCurrencies = () => {
        return new Promise(async (resolve, reject) => {
            this.getInstance()
            .get(Constants.URLs.LIST_ITEMS_LIST.CURRENCIES)
            .then((response) => {
                console.log("response:", response);
                let activeOnly = response.data.filter((curr, index)=>{
                    return curr.status === 'ac';
                });
                console.log("active Only:", activeOnly);
                resolve(activeOnly);
            })
            .catch(err => reject(err))
        });
    }

    getCurrencies = (unset=false) => 
            this.cachedResponse(unset, Constants.INDEXED_KEYS.CURRENCIES, this.fetchCurrencies);

    fetchAcctTypes = () => {
        return new Promise(async (resolve, reject) => {
            this.getInstance()
            .get(Constants.URLs.LIST_ITEMS_LIST.ACCNT_TYPES)
            .then((response) => {
                console.log("response:", response);
                let activeOnly = response.data.filter((curr, index)=>{
                    return curr.status === 'ac';
                });
                console.log("active Only:", activeOnly);
                resolve(activeOnly);
            })
            .catch(err => reject(err))
        });
    }

    getAcctTypes = (unset=false) => 
            this.cachedResponse(unset, Constants.INDEXED_KEYS.ACCT_TYPES, this.fetchAcctTypes);

      
}
