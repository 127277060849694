import React, { Component } from 'react';
import { Button, Grid, Dialog, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { AgGridReact } from 'ag-grid-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {concat as _concat, cloneDeep as _cloneDeep} from 'lodash';
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import LoadingOverlay from '../common/loadingOverlay';
import {TranStyle} from './TranStyle';
import { TranGrid } from './TranGrid';
import { prepareExport } from './prepareExport';
import './Tran.css'
import { getInstance } from '../utils/axiosLoader';

import Constants from '../utils/constants'
import NewTran from './NewTran';
import mapDispatchToProps from '../actions/dispatchProps';
import mapStateToProps from '../actions/stateToProps';
import moment from 'moment-timezone';
import LinkTran from './LinkTran';
import CopyTran from './CopyTran';
import WidgetYearlyTrend from '../widgets/YearlyTrend';
import WidgetTranTypeSplits from '../widgets/TranTypeSplits';
import { withSnackbar } from 'notistack';
import SelectAccount from '../common/selectAccount';
import ReceiptManager from '../common/ReceiptManager';
import { AccountHandler } from '../api';
import NewChitTermForm from '../account/NewChitTermForm';
import TranBaseActions from './TranBaseActions';
import TranChitActions from './TranChitActions';
import ChitPayDashboard from './ChitPayDashboard';
import Messenger from '../utils/Messenger';
import Calculator from '../calc/Calculator';
import { WebClientStore } from 'web-client-store';
let {Session} = WebClientStore;

let SESSION_KEYS = Constants.SESSION_KEYS;
let defaultFilter = {filterCrDr: -1, filterCrDrText: 'Show Credits Only'};
let _dragStarted = false;

class Tran extends Component {
    constructor(props) {
		super(props);
		this._accountHandler = new AccountHandler(this);
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
		// this.resetShowAll = this.resetShowAll.bind(this)
		this.handleResetAllFilters = this.handleResetAllFilters.bind(this)
		this.handleSearchChange = this.handleSearchChange.bind(this)
		this.applyQuickFilter = this.applyQuickFilter.bind(this)
	}

	updateWindowDimensions() {
	// 	let changeViewMode = window.innerWidth <= 420 ? 3 : this.state.gridEditMode;
	// 	let mode = this.state.gridEditMode;
	// 	let _grid = TranGrid(this.state.roles);
	// 	let colDef = mode ? _grid.columns : 
	// 		(this.state.selectedAccount.type === 'CHIT' ? _grid.columnsLockedChit :
	// 			_grid.columnsLocked);
	// 	if (this.state.width > 0 && this.state.width <= 799) {
	// 		colDef = _grid.columnsCompact;
	// 		if (mode) {
	// 			colDef = _concat({
	// 				width: 40,
	// 				rowDrag: true
	// 			  }, colDef)
	// 		}
	// 	} 
	// 	this.setState({ 
	// 		width: window.innerWidth, 
	// 		height: window.innerHeight,
	// 		gridEditMode: changeViewMode,
	// 		tranColDef: colDef
	// 	});
	if (this.setState)
		this.setState({
			width: window.innerWidth,
			height: window.innerHeight
		})
	}

	state = {
		width: 0,
		height: 0,
		gridEditMode: false,
		enabledAdd: false,
		enabledDelete: false,
		enabledUpdate: false,
        enabledBulkUpdate: false,
        enabledAttach: false,
        enabledOpenAttach: false,
		enabledLink: false,
		enabledUnlink: false,
		enabledCopy: false,
		enabledMove: false,
		enabledClone: false,
		enableSubmitOrder: false,
		enableSwapTypes: false,
		enableAddChitTerm: false,
		enableChitPaymentDashboard: false,
		enableDeleteLastChitTerm: true,
		tranTypes: [],
		tranTypesCrDr: [],
		tranTypeObj: {},
		rowsSelected: [],
		rowsToUnlink: [],
		open: {
			createModal   : false,
			updateModal : false,
			bulkUpdateModal: false,
			debitModal: false,
            creditModal: false,
            attachModal: false,
			linkModal: false,
			copyModal: false,
			moveModal: false,
			widgetYearlyTrends: false,
            widgetTranTypeSplits: false,
            confirmation: false,
			chitPaymentDashboard: false,
		},
        roles: {
            owner: false,
            admin: false,
            editor: false,
            viewer: false,
			any: false,
        },
		openOverlay              : false,
		accountLineUp : [Constants.DEFAULT_ACCOUNT],
		selectedAccount: Constants.DEFAULT_ACCOUNT,
		calcVal: '1',
		showCalc: false,
		trans : [],
		transOriginal: [],
		tranOrder: [],
		filters: _cloneDeep(defaultFilter),
		searchTerm: '',
        tranColDef: TranGrid().columnsLocked, //default
        linkReference: ''
	};

	componentDidMount = () => {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
		this.props.changePageTitle('View Transactions')
		
		try {
			const {match: {params}} = this.props;
			if (params.accountId) {
				Session.set(SESSION_KEYS.ACTIVE_ACCOUNT, params.accountId);
            }
            if (params.linkId) {
                // console.log('link id received:' + params.linkId);
                this.setState({ linkReference: params.linkId })
			}	
		} catch (error) {
			console.log(error)
		}
	};
      
	componentWillUnmount() {
			window.removeEventListener('resize', this.updateWindowDimensions);
	}
	
    _force_reload_account = () => {
        return (
            this._accountHandler.getAccountCached(this.state.selectedAccount._id, true).then((ac)=>{
                // console.log('AccountInfo->renderActionInfoForm->AFTER ac', ac);
                this.setState({ selectedAccount: ac });
                // force-refresh transactions on next visit to transactions page
                Session.set(this.state.selectedAccount._id + '_req_ts', moment().format('YYYYMMDDhhmmss'));
                // window.location.reload();
            })
        )
    }

    handleGetAccountMembers = async () => {
        this.setState({
            openOverlay: true
        })
        let {_id, uid} = this.state.selectedAccount;
        this._accountHandler.getUserRoles(_id, uid).then((roles)=>{
            this.setState({
                roles,
                openOverlay: false
            })
        })
    }
	
	handleResetAllFilters = () => {
		this.applyQuickFilter('')
		this.gridApi.setFilterModel(null);
	}
	// resetShowAll = () => this.applyAccountChange('unknown');
	handleSearchChange = (e) => this.applyQuickFilter(e.target.value)
	applyQuickFilter = (q) => {
		this.gridApi.setQuickFilter(q);
		this.gridApi.setRowData(this.state.trans);
		this.setState({ searchTerm: q })
	}

	handleGetListItems = async () => {
		// Session.set(SESSION_KEYS.ACTIVE_TRAN_TYPE, 'Unknown');
		let retrieveFrom = Constants.URLs.TRAN_TYPES + '/ac';
		let type = 'DEF'; //default value
		if (Session.get(SESSION_KEYS.ACTIVE_ACCOUNT_TYPE)) {
			type = Session.get(SESSION_KEYS.ACTIVE_ACCOUNT_TYPE);
		}
		this.setState({ openOverlay: true });
		await getInstance()
			.post(retrieveFrom, {acType: type})
			.then((response) => {
				let trans = response.data;
				let typeObj = trans.reduce((obj, type)=>{
					obj[type.code] = type.name;
					return obj;
				},{});
				// console.log('typeObj', typeObj);
				let sorted = trans.sort((a, b)=> {
					if (a.code < b.code) return -1;
					return 0;
				});
				this.setState({ 
					openOverlay: false, 
					enabledDelete: false,
					enabledUpdate: false, 
					enabledBulkUpdate: false, 
					enabledLink: false, 
					enabledUnlink: false, 
					enabledCopy: false,
					enabledMove: false,
					enabledClone: false,
					enabledAttach: false,
					enabledOpenAttach: false,
					tranTypes: sorted, 
					tranTypesCrDr: sorted,
					tranTypeObj: typeObj
				});
			})
			.catch((error) => {
				if (error.response)
				console.log(error);
				this.props.enqueueSnackbar('Error: Could not be able to retrieve accounts', {
					variant : 'error'
				});
			}).finally(()=>{
				if (Session.get('force_refresh')) {
					Session.unset('force_refresh');
					this.handleRefreshRequest();
				} else {
					this.handleRefreshTranGrid();
				}
			})
	}

	handleRefreshRequest = () => {
		// let ac = Session.get(SESSION_KEYS.ACTIVE_ACCOUNT);
		// if (ac) {
		// 	Session.set(ac + '_req_ts', moment().format('YYYYMMDDhhmmss'));
		// }
		this.handleRefreshTranGrid();
	}

	handleRefreshTranGrid = (quite = false ) => {
		if (Session.get(SESSION_KEYS.ACTIVE_ACCOUNT)) {
            let ac = Session.get(SESSION_KEYS.ACTIVE_ACCOUNT);
            if (ac === 'unknown') {
                return;
            }
		 	let retrieveFrom = `${Constants.URLs.TRANS_OF_ACCOUNT}/${ac}`;
			this.setState({ openOverlay: true, filters: _cloneDeep(defaultFilter) });
			let _trans = this.state.trans;
			if (Session.get(ac + '_req_ts')) {
				retrieveFrom += '?request=' + Session.get(ac + '_req_ts');
			}
			getInstance()
				.get(retrieveFrom)
				.then((response) => {
					let typeObj = this.state.tranTypeObj;/* .reduce((obj, type)=>{
						obj[type.code] = type.name;
						return obj;
					},{}); */
					let data = response.data; //.docs;
					//inject transaction type name from code
					data.forEach((t)=>{
						if (typeObj.hasOwnProperty(t.type)) {
							t.typeName = typeObj[t.type]
						}
					});
					let orders = data.reduce((arr, item) => {
						arr.push({ _id: item._id, sno: item.sno });
						return arr;
					}, []);
					if (!quite) {
						this.props.enqueueSnackbar('Transactions refreshed successfully.', {
							variant : 'success',
							autoHideDuration: 1000
						});
					}
					_trans = response.data;
					this.setState({ trans: data, transOriginal: _cloneDeep(data), tranOrder: orders });
				})
				.catch((error) => {
					console.log(error);
					if (!quite) {
						this.props.enqueueSnackbar('Error: Could not be able to retrieve funds', {
							variant : 'error'
						});
					}
				})
				.finally(()=>{
					if (Session.get(ac + '_req_ts')) {
						Session.unset(ac + '_req_ts');
					}
					this.setState({ 
						openOverlay: false, 
						enabledDelete: false,
						enabledUpdate: false, 
						enabledBulkUpdate: false, 
						enabledLink: false, 
						enabledUnlink: false, 
						enabledCopy: false,
						enabledMove: false,
						enabledClone: false,
						rowsSelected: [],
						gridEditMode: false,
						enabledAttach: false,
						enabledOpenAttach: false,
						enableChitPaymentDashboard: _trans.length > 0
					});

					this.handleEditMode();

					//scroll to referenced link row
					let {trans, linkReference} = this.state;
					if (linkReference) {
							// console.log('link reference received:' + linkReference);
							let index = -1;
							trans.some(function (tran) {
									// console.log('searching at index:'+ index + " value: " + tran._id)
									index++;
									return (tran._id === linkReference);
							});
							// console.log("scrolling to position:" + index);
							if (this.gridApi) {
									this.gridApi.ensureIndexVisible(index);
							}
					}
					if (this.gridApi) {
							this.gridApi.setRowData(_trans);
					}

					// //hide link/beneficiary columsn in trans page
					// if (this.gridColumnApi) {
					//     this.gridColumnApi.setColumnVisible('link_io', this.state.roles.owner);
					//     this.gridColumnApi.setColumnVisible('link_ac.code', this.state.roles.owner);
					// }
				})
		}
	};


	handleRowHighlights = params => {
		// let isCC = (Session.get(SESSION_KEYS.ACTIVE_ACCOUNT_TYPE) && 
		// 		(Session.get(SESSION_KEYS.ACTIVE_ACCOUNT_TYPE) === 'CC'));
		let isCC = false;
		if (params.data.cr > 0) return isCC ? 'tran-debit-row' : 'tran-credit-row';
		if (params.data.dr > 0) return isCC ? 'tran-credit-row': 'tran-debit-row';
	}
	
	handleOpenModal = async (modal) => {
		let tran = this.state.rowsSelected[0];
		let showModal = this.state.open;
		showModal.createModal = false;
		showModal.updateModal = false;
		showModal.bulkUpdateModal = false;
		showModal.linkModal = false;
		showModal.copyModal = false;
		showModal.moveModal = false;
		showModal.debitModal = false;
        showModal.creditModal = false;
        showModal.confirmation = false;
		let filterCrDr = "B";
		switch (modal) {
			case 'credit':
                filterCrDr = "C"
				showModal.createModal = true;
				showModal.creditModal = true;
				break;
			case 'debit':
				filterCrDr = "D";
				showModal.createModal = true;
				showModal.debitModal = true;
				break;
			case 'update':
				if (tran && tran._id !== null && tran._id !== '') {
					if (tran.cr > 0) {
                        showModal.creditModal = true;
                        filterCrDr = "C"
					} else {
						filterCrDr = "D"
						showModal.debitModal = true;
					}
					showModal.updateModal = true;
				} else {
					this.props.enqueueSnackbar('Please chose a valid transaction to update.', {
						variant : 'warning'
					});
				}
				break;
			case 'bulkupdate':
				showModal.bulkUpdateModal = true;
                break;
            case 'attach':
                showModal.attachModal = true;
                break;
			case 'link':
				if (tran && tran._id !== null && tran._id !== '') {
					if (tran.dr > 0) {
                        showModal.creditModal = true;
                        filterCrDr = "C"
					} else {
						filterCrDr = "D"
						showModal.debitModal = true;
					}
					showModal.linkModal = true;
				} else {
					this.props.enqueueSnackbar('Please chose a valid transaction to link.', {
						variant : 'warning'
					});
				}
				break;
			case 'bulk':
					showModal.linkModal = true;
				break;
			case 'copy':
					showModal.copyModal = true;
				break;
			case 'move':
					showModal.moveModal = true;
				break;
			case 'graph1':
				showModal.widgetYearlyTrends = true;
				break;
			case 'graph2':
				showModal.widgetTranTypeSplits = true;
                break;
            case 'confirmation':
                showModal.confirmation = true;
                break;
			case 'chit-pay-dashboard':
				showModal.chitPaymentDashboard = true;
				break;
			default:
				break;
		}

		let _tranTypesCrDr = this.state.tranTypes.filter((type)=>{
			if (type.tag === 'B') {
                return true;
            } else if ((type.tag === 'C' || type.tag === 'D') && filterCrDr === 'B') {
                return true;
            } 
            return type.tag === filterCrDr;
		});


		this.setState({
			open                  : showModal,
			tranTypesCrDr         : _tranTypesCrDr
		});
	};

	handleCloseModal = () => {
		let showModal = this.state.open;
		showModal.createModal = false;
		showModal.updateModal = false;
		showModal.bulkUpdateModal = false;
		showModal.attachModal = false;
		showModal.linkModal = false;
		showModal.copyModal = false;
		showModal.moveModal = false;
		showModal.creditModal = false;
		showModal.debitModal = false;
		showModal.widgetYearlyTrends = false;
        showModal.widgetTranTypeSplits = false;
        showModal.confirmation = false;
		showModal.chitPaymentDashboard = false;
		this.setState({
			open             : showModal
		});
	};

	handleDownloadTrans = async () => {
		this.gridApi.exportDataAsCsv(
			this.state.selectedAccount.type === 'CHIT' ?
			prepareExport.exportChitTrans(this.state.selectedAccount.name) :
			prepareExport.exportAccountTrans(this.state.selectedAccount.name)
		);
	}

	handleDeleteTrans = async () => {
		let rowsToDelete = this.state.rowsSelected;
		let selected = rowsToDelete.reduce((arr, item) => {
			arr.push({_id: item._id});
			return arr;
		}, []);
		let payload = {
			account: Session.get(SESSION_KEYS.ACTIVE_ACCOUNT),
			trans: selected
		}
		let deleteTranURL = Constants.URLs.TRANS + '/delete';
		let _trans = this.state.trans;
		this.setState({ openOverlay: true });
		await getInstance()
			.post(deleteTranURL, payload)
			.then((response) => {
				// let cnt = response.data.status.reduce((m, item) => {
				// 	m += item.deleted;
				// 	return m;
				// }, 0);
				this.props.enqueueSnackbar('Transaction(s) deleted successfully', {
					variant : 'success'
				});
				_trans = response.data.data;
			})
			.catch((error) => {
				console.log(error);
				let msg = 'Error while deleting the transaction(s). Please check console.';
				try {
						if (error.response.data.message) msg = error.response.data.message;
				} catch (error) {}
				this.props.enqueueSnackbar(msg, {
					variant : 'error'
				});
			})
			.finally(()=>{
				this.setState({ 
					openOverlay: false, 
					enabledDelete: false, 
					enabledUpdate: false, 
					enabledBulkUpdate: false, 
					enabledLink: false, 
					enabledUnlink: false, 
					enabledCopy: false,
					enabledMove: false,
					enabledClone: false,
					rowsSelected: [], 
					trans: _trans 
				});
				// this.handleRefreshTranGrid(true)
				this.gridApi.setRowData(_trans);
				Session.set('force_refresh', 'delete_trans');
				this.handleRefreshRequest();
			})
	}

	handleOnGridReady = params => {
		this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
		// if (Session.get('force_refresh')) {
		// 	Session.unset('force_refresh');
		// 	this.handleRefreshRequest();
		// } else {
		// 	this.handleRefreshTranGrid();
		// }
	};

	onSelectionChanged() {
		if (_dragStarted) return;
		let {owner, admin, editor, viewer}  = this.state.roles
		let isAuthorized = owner || admin || editor;
		let flagDelete = false;
		let flagUpdate = false;
		let flagBulkUpdate = false;
		let flagLink = false;
		let flagUnlink = false;
		let flagCopy = false;
		let flagMove = false;
        let flagClone = false;
        let flagAttach = false;
        let flagOpenAttach = false;
		let flagSwapTypes = true;
		let _calcVal = this.state.calcVal;

		let selectedRows = this.gridApi.getSelectedRows();
		let oneRow = selectedRows[0];
		if (oneRow && oneRow._id && oneRow._id !== '') {
			//proceed
			let attachExists = oneRow.hasOwnProperty('receipts') && oneRow.receipts[0];
			if (attachExists) {
				flagOpenAttach = isAuthorized || viewer;
			} else {
				flagAttach = isAuthorized && selectedRows.length === 1;
			}
			//Read the amount value for calculator
			_calcVal = oneRow.bal;
		} else {
			//return;
		}
		let isNotChitFund = this.state.selectedAccount.type !== 'CHIT';
		flagDelete = isNotChitFund && isAuthorized && selectedRows.length > 0;
		flagUpdate = isAuthorized && selectedRows.length === 1;
		flagBulkUpdate = isNotChitFund && isAuthorized && selectedRows.length > 0;
		flagClone = isNotChitFund && isAuthorized && selectedRows.length === 1;
		flagCopy = isNotChitFund && isAuthorized && selectedRows.length > 0;
		flagMove = isNotChitFund && isAuthorized && selectedRows.length > 0;
		flagLink = isNotChitFund && isAuthorized && selectedRows.length > 0;
		if (selectedRows.length === 1 && oneRow.link && oneRow.link !== '') {
			flagLink = false;
		}
		let unlinkFilter = selectedRows.filter((row)=>{
			return row.link !== '';
		});
        flagUnlink = unlinkFilter && unlinkFilter[0] && unlinkFilter[0].link && isAuthorized;
        flagSwapTypes = isNotChitFund && isAuthorized && selectedRows.length > 0;

		this.setState({ 
			rowsSelected: selectedRows, 
			enabledDelete: flagDelete, 
			enabledUpdate: flagUpdate,
			enabledBulkUpdate: flagBulkUpdate,
			enabledLink: flagLink,
			enabledUnlink: flagUnlink,
			enabledCopy: flagCopy,
			enabledMove: flagMove,
            enabledClone: flagClone,
            enabledAttach: flagAttach,
            enabledOpenAttach: flagOpenAttach,
			rowsToUnlink: unlinkFilter,
			enableSwapTypes: flagSwapTypes,
			calcVal: _calcVal
		})
	}

	createColumnFilter = (instance, type, filter) => {
		var creditFilterComponent = this.gridApi.getFilterInstance(instance);
		var model = {
		  type: type,
		  filter: filter,
		  filterTo: null,
		};
		creditFilterComponent.setModel(model);
		this.gridApi.onFilterChanged();
	}

	destroyColumnFilter = (instance) => {
		this.gridApi.destroyFilter(instance);
	};

	onRowDragEnter = () => {
		_dragStarted = true;
	}

	onRowDragEnd = async (params) => {
		_dragStarted = false;
		try {
			let original = _cloneDeep(this.state.tranOrder);
			let data = this.state.tranOrder;
			let i = 0;
			params.api.forEachNode((node) => {
				data[i++]._id = node.data._id;
			});
			this.setState({ tranOrder: data, enableSubmitOrder: JSON.stringify(data)!==JSON.stringify(original) })
		} catch (error) {console.log(error)}
	};

	onRowDragLeave = async (params) => {
		_dragStarted = false;
		this.gridApi.setRowData(this.state.trans);
		this.setState({ enableSubmitOrder: false })
	};

	handleSubmitNewTransactionOrder = async ()  => {
		this.setState({ enableSubmitOrder: false, openOverlay: true });
		let payload = {
			account: { _id: Session.get(SESSION_KEYS.ACTIVE_ACCOUNT) },
			trans: this.state.tranOrder
		};
		await getInstance()
			.patch(Constants.URLs.TRANS + '/reorder', payload)
			.then((response) => {
				this.props.enqueueSnackbar(response.data.message, {
					variant : 'success'
                });
                //_trans = response.data.data;
				// this.setState({ trans: response.data.data, openOverlay: false, gridEditMode: false });
				// this.gridApi.setRowData(response.data.data);
			})
			.catch((error) => {
				console.log(error);
				this.props.enqueueSnackbar('Error: Could not update the transactions', {
					variant : 'error'
                });
				// this.setState({ openOverlay: false });
				// this.gridApi.setRowData(this.state.trans);
            })
            .finally(()=>{
                this.setState({ openOverlay: false, gridEditMode: false });
                // this.gridApi.setRowData(_trans);
                this.handleRefreshRequest();
                this.handleEditMode();
            })
	}

	handleSubmitSwapTransactions = async ()  => {
		this.setState({ enableSwapTypes: false, openOverlay: true });
		let selected = this.state.rowsSelected.reduce((arr, item) => {
			arr.push({
				_id: item._id,
				cr: item.dr,
				dr: item.cr
				//TODO: Also identify the swapped transaction type (code)
			});
			return arr;
		}, []);
		console.log('to be swapped:\n', selected);

		let payload = {
			account: { _id: Session.get(SESSION_KEYS.ACTIVE_ACCOUNT) },
			trans: selected
		};

		let _selectedAccount = this.state.selectedAccount;
		await getInstance()
			.patch(Constants.URLs.TRANS + '/many', payload)
			.then((response) => {
				if (response.data.accountNewBal) {
					_selectedAccount.bal = response.data.accountNewBal;
					this.setState({
						selectedAccount: _selectedAccount
					})
				}
				if (response.data.message) {
					this.props.enqueueSnackbar(response.data.message, {
						variant : 'success'
					});
				}
			})
			.catch((error) => {
				console.log(error);
				this.props.enqueueSnackbar('Error: Could not update the transactions', {
					variant : 'error'
                });
            })
            .finally(()=>{
                this.setState({ openOverlay: false, gridEditMode: false });
                // this.gridApi.setRowData(_trans);
                this.handleRefreshRequest();
                this.handleEditMode();
            })
	}

	handleEditMode() {
        //console.log('handle Edit mode:' + this.state.gridEditMode)
        let mode = this.state.gridEditMode;
        let _grid = TranGrid(this.state.roles);
		let colDef = mode ? _grid.columns : 
			(this.state.selectedAccount.type === 'CHIT' ? _grid.columnsLockedChit :
			_grid.columnsLocked);
		if (this.state.width <= 799) {
			colDef = _grid.columnsCompact;
			if (mode) {
				colDef = _concat({
					width: 40,
					rowDrag: true
				  }, colDef);
			}
        } 
		let updates = {
			enabledDelete: false,
			enabledUpdate: false,
			enabledBulkUpdate: false,
			enabledLink: false,
			enabledUnlink: false,
			enabledCopy: false,
			enabledMove: false,
			enabledClone: false,
			enableSubmitOrder: false,
			gridEditMode: !mode,
			tranColDef: colDef
		}
		if (mode) {
			let original = _cloneDeep(this.state.transOriginal);
			updates.trans = original;
			this.gridApi.setRowData(original);
		}
        this.setState(updates);
        
        //hide link/beneficiary columsn in trans page
        // if (this.gridColumnApi) {
        //     this.gridColumnApi.setColumnVisible('link_io', this.state.roles.owner);
        //     this.gridColumnApi.setColumnVisible('link_ac.code', this.state.roles.owner);
        // }
	}

	handleShowCalc(hide=false) {
		let _newVal = !this.state.showCalc;
		if (hide) {
			_newVal = false;
		}
		this.setState({showCalc: _newVal})
	}

	/**
	 * Render UI components
	 */

	render() {
		return (
			<div className="content-fluid">
				<div className="row">
					<div className="col-md-12">
						<div className="card">
							{this.state.width > 799 && <div className="card-header">
								<h4 className="card-title">Account Transactions</h4>
								<p className="card-category">View and manage account transactions.</p>
							</div>}
							{this.state.showCalc && 
								<Calculator 
									question={this.state.calcVal} 
									onClose={()=>this.handleShowCalc(true)}
									/>}
							<div className="card-body">
								<Grid className="row select-account">
									<Grid container id="trans-account-select">
										{this.renderSelectAccount()}
										{/* {!isCompact && this.renderSelectedAccountBalance()} */}
										{this.state.width > 1210 && this.renderChitActions()}
									</Grid>
								</Grid>
								<Grid className="row main-actions">
									{this.renderActions()}
								</Grid>
								{this.renderTranGrid()}
								{this.renderGridTitle()}
								{this.state.open.createModal && this.renderModalNewTran()}
								{this.state.enableAddChitTerm && this.renderModalNewChitTermTran()}
								{this.state.open.updateModal && this.renderModalUpdateTran()}
                                {this.state.open.bulkUpdateModal && this.renderModalBulkUpdateTran()}
                                {this.state.open.attachModal && this.renderModalTranAttach()}
								{this.state.open.linkModal && this.renderModalLinkTranMulti()}
								{(this.state.open.copyModal || this.state.open.moveModal) && this.renderModalCopyTranMulti()}
								{this.state.open.widgetYearlyTrends && this.renderWidgetYearlyTrend()}
                                {this.state.open.widgetTranTypeSplits && this.renderWidgetTranTypeSplits()}
								{this.state.open.chitPaymentDashboard && this.renderChitPaymentDashboard()}
                                {this.state.open.confirmation && 
									<Dialog
                                		open={this.state.open.confirmation}>
										<Typography>
                                    		{Messenger.getAccountArchiveConfirmation()}
                                		</Typography>
										<Button onClick={()=>this._accountHandler.deleteAccount()}>Yes</Button>
										<Button onClick={()=>this.handleCloseModal("")}>No</Button>
									</Dialog>}
								{this.state.openOverlay && <LoadingOverlay />}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

	renderSelectAccount() {
		return (
			<SelectAccount
				caller="Tran"
				onReady={(accounts, ac, roles, trans) => {
					this.setState({
						enabledAdd: ((roles.owner || roles.admin || roles.editor) && ac.code !== 'unknown' && ac.type !== 'CHIT'),
						filters: _cloneDeep(defaultFilter),
						accountLineUp: accounts,
						selectedAccount: ac,
						roles
					})
					if (ac._id !== 'unknown') {
						this.handleGetListItems();
					}
				}}
				onChange={(accounts, ac, roles) => {
					if (ac._id !== 'unknown') {
						this.props.history.push(`/transactions/${ac._id}`);
						window.location.reload();
					}
				}} />
		)
	}

	renderSelectedAccountBalance() {
		let bal = "unknown";
		let account = this.state.selectedAccount;
		if (account.curr) {
			// console.log('account selected', account);
			let locale = account.curr === 'INR' ? 'en-IN' : 'en-US';
			bal = Number(account.bal);
			let creditBal = '';
			if (account.type === 'CC') {
				if (account.bal <= 0) {
					creditBal = 'credit-bal';
					if (account.bal < 0) bal *= -1;
				}
			}
			bal = bal.toLocaleString(locale, { style: 'currency', currency: account.curr });
			if (creditBal === 'credit-bal' && account.bal < 0) {
				bal += ' CR'
			}
		}
		return (
			<Grid item lg={2} md={2} sm={12} xs={12}>
				<span>{bal}</span>
			</Grid>
		)
	}

	renderActions() {
		return(
			<TranBaseActions 
				history={this.props.history}
				selectedAccount={this.state.selectedAccount}
				trans={this.state.trans}
				accountRoles={this.state.roles}
				isCompact={this.state.width <= 612}
				gridEditMode={this.state.gridEditMode}
				rowsToUnlink={this.state.rowsToUnlink}
				rowsSelected={this.state.rowsSelected}
				// `Core` drop down buttons and callbacks
				enabledAdd={this.state.enabledAdd}
				enableSwapTypes={this.state.enableSwapTypes}
				onSubmitSwapTransactions={()=>this.handleSubmitSwapTransactions()}
				enabledUpdate={this.state.enabledUpdate}
				enabledBulkUpdate={this.state.enabledBulkUpdate}
				enabledDelete={this.state.enabledDelete}
				onDeleteTrans={()=>this.handleDeleteTrans()}
				enabledLink={this.state.enabledLink}
				enabledUnlink={this.state.enabledUnlink}
				enabledCopy={this.state.enabledCopy}
				enabledMove={this.state.enabledMove}
				enabledClone={this.state.enabledClone}
				// `More` drop down buttons and callbacks
				onDownloadTrans={()=>this.handleDownloadTrans()}
				// Top bar buttons and callbacks
				enabledAttach={this.state.enabledAttach}
				enabledOpenAttach={this.state.enabledOpenAttach}
				enableSubmitOrder={this.state.enableSubmitOrder}
				onSubmitNewTransactionOrder={()=>this.handleSubmitNewTransactionOrder()}
				//Standard callbacks
				onOpenModal={(modal) => this.handleOpenModal(modal)}
				onRefreshTranGrid={(quite)=>this.handleRefreshTranGrid(quite)}
				onRefreshRequest={()=>{
					let ac = Session.get(SESSION_KEYS.ACTIVE_ACCOUNT);
					if (ac) {
						Session.set(ac + '_req_ts', moment().format('YYYYMMDDhhmmss'));
					}
					this.handleRefreshRequest()
				}}
				onEditMode={()=>this.handleEditMode()}
				onDeleteAccount={()=>this._accountHandler.deleteAccount()}
				onCreateColumnFilter={(i,t,f)=>this.createColumnFilter(i,t,f)}
				onDestroyColumnFilter={(i)=>this.destroyColumnFilter(i)}
				onUpdateEnableProperty={(props)=>{
					this.setState(props)
				}}
				onShowCalc={()=>this.handleShowCalc()}
				showOrHideCalc={!this.state.showCalc}
			/>
		)
	}

	renderChitActions() {
		return(
			<TranChitActions 
				history={this.props.history}
				selectedAccount={this.state.selectedAccount}
				trans={this.state.trans}
				rowsSelected={this.state.rowsSelected}
				accountRoles={this.state.roles}
				isCompact={this.state.width <= 612}
				onOpenModal={(modal) => this.handleOpenModal(modal)}

				enableAddChitTerm={this.state.enableAddChitTerm}
				enableChitPaymentDashboard={this.state.enableChitPaymentDashboard}
				enableDeleteLastChitTerm={this.state.enableDeleteLastChitTerm}
				onACForceRefreshRequest={()=>this._force_reload_account()}
				onRefreshRequest={()=>this.handleRefreshRequest()}
				onReloadRequest={()=>window.location.reload()}
				onAddChitTerm={()=> {
					this.setState({
						enableAddChitTerm: true
					})
				}}
				onDashboard={()=>{
					this.handleOpenModal('chit-pay-dashboard')
				}}
			/>
		)
	}

	renderGridTitle() {
		let cnt = this.state.rowsSelected.length;
		let crSum = this.state.rowsSelected.reduce((sum, row)=>{
			sum += row.cr
			return sum;
		}, 0);
		let cr = (Math.round(crSum * 100) / 100).toFixed(2);
		let drSum = this.state.rowsSelected.reduce((sum, row)=>{
			sum += row.dr
			return sum;
		}, 0);
		let dr = (Math.round(drSum * 100) / 100).toFixed(2);
		let diff = (Math.round((cr - dr) * 100) / 100).toFixed(2);
		return (
			<Grid container id="trans-grid-notification">
				<Grid item lg={6} md={6} sm={12} xs={12}>
					{/* <div className="row"> */}
					<span>Viewing {this.state.trans.length} transactions From Account:&nbsp; 
					<strong>{this.state.selectedAccount.code}</strong></span>
					{/* </div>
					<div className="row"> */}
				</Grid>
				<Grid item lg={6} md={6} sm={12} xs={12}>
					<span>{`Count: ${cnt} Credit Sum: ${cr} Debit Sum: ${dr} Diff: ${diff}`}</span>
					{/* </div> */}
				</Grid>
			</Grid>
		)
	}

	renderTranGrid() {
		const { classes } = this.props;
        const agStyles = `ag-theme-${this.state.width > 799 ? 'alpine' : 'balham'} ${classes.agThemeModified}`;
		return (
			<Grid container id='trans-tran-grid' /* style={{"height": "70%", width: "96%", marginLeft: "2%", marginTop: "10px"}} */ >
				<Grid item  md={12} sm={12} xs={12}>
					<div className={agStyles}>
						<AgGridReact
							gridOptions={{
								defaultColDef: {
									resizable: true,
									cellStyle: { 'padding-left': '4px', 'padding-right': '2px' }
								}
							}}
							onRowDragEnter={this.onRowDragEnter}
							onRowDragEnd={this.onRowDragEnd}
							onGridReady={this.handleOnGridReady}
							onRowDragLeave={this.onRowDragLeave}
							columnDefs={this.state.tranColDef}
							rowData={this.state.trans}
							getRowClass={this.handleRowHighlights}
							rowSelection="multiple"
							onSelectionChanged={this.onSelectionChanged.bind(this)}
							rowDragManaged={true}
                            animateRows
                            enableCellTextSelection="true"
                            ensureDomOrder="true"
							// pagination={!this.state.gridEditMode}
							// paginationPageSize={25}
						/>
					</div>
				</Grid>
			</Grid>
		)
	}

	renderModalNewTran() {
        // console.log("adding selected row date to new tran dialog:" + JSON.stringify(selected));
        // if (Array.isArray(selected) && selected.length === 1 && selected[0].date) {
        //     newTran.date.value = selected[0].date.substr(0, 10);
        // } 
		return (
			<NewTran
				display={this.state.open.createModal}
				onCancel={() => {
					this.handleCloseModal();
				}} 
				onSubmit={() => {
						this.handleCloseModal();
						// this.handleSubmitNewTransactionOrder();
						this.handleRefreshRequest();
						// this.handleRecalculateAccount();
				}} 
				tranTypes={this.state.tranTypesCrDr}
				account={this.state.selectedAccount}
				isCredit={this.state.open.creditModal}
				actionUpdate={false}
				transactions={[]}
			/>
		)
	}
	
	renderModalNewChitTermTran() {
        // let selected = this.gridApi.getSelectedRows();
        // let newTran = _cloneDeep(tranObject);
        // console.log("adding selected row date to new tran dialog:" + JSON.stringify(selected));
        // if (Array.isArray(selected) && selected.length === 1 && selected[0].date) {
        //     newTran.date.value = selected[0].date.substr(0, 10);
        // } 
		return (
			<NewChitTermForm
				display={this.state.enableAddChitTerm}
				onCancel={() => {
					this.setState({ enableAddChitTerm: false });
				}} 
				onSubmit={() => {
					this.setState({ enableAddChitTerm: false });
					this.handleRefreshRequest();
					this._force_reload_account();
					// Need to reload account information; so reloading
					window.location.reload();
				}} 
				modeUpdate={false}
				asDialog={true}
				account={this.state.selectedAccount}
			/>
		)
	}

	renderModalUpdateTran() {
		return (
			<NewTran
				display={this.state.open.updateModal}
				onCancel={() => {
					this.handleCloseModal();
				}} 
				onSubmit={() => {
						this.handleCloseModal();
						//TODO: do below update only if cr/dr is changed.
						// this.handleSubmitNewTransactionOrder();
						this.handleRefreshRequest();
						// this.handleRecalculateAccount();
				}} 
				tranTypes={this.state.tranTypesCrDr}
				account={this.state.selectedAccount}
				isCredit={this.state.open.creditModal}
				actionUpdate={true}
				transactions={this.state.rowsSelected}
			/>
		)
	}

	
	renderModalBulkUpdateTran() {
		return (
			<NewTran
				display={this.state.open.bulkUpdateModal}
				onCancel={() => {
					this.handleCloseModal();
				}} 
				onSubmit={() => {
						this.handleCloseModal();
						// this.handleSubmitNewTransactionOrder();
						this.handleRefreshRequest();
						// this.handleRecalculateAccount();
				}} 
				tranTypes={this.state.tranTypesCrDr}
				account={this.state.selectedAccount}
				isCredit={this.state.open.creditModal}
				actionUpdate={false}
				multiMode={true}
				transactions={this.state.enabledBulkUpdate ? this.state.rowsSelected : []}
			/>
		)
	}
	
	renderModalTranAttach() {
        let ids = this.state.rowsSelected.reduce((arr, tran)=>{
			arr.push(tran._id);
			return arr;
		},[]);
		return (
			<Dialog open={this.state.open.attachModal}
				fullWidth={true}
				maxWidth="md"
				hideBackdrop={true}
				style={{modal: { padding: 0 }}}
				onClose={(e, reason)=>{
					if (reason === "backdropClick") this.handleCloseModal()
				}}
				PaperProps={{ style: { backgroundColor: Constants.COLORs.VANKAYA_250 }}}>
				<ReceiptManager
					display={this.state.open.attachModal}
					entity={'tran'}
					entityId={ids[0]}
					selectedAccount={this.state.selectedAccount}
					roles={this.state.roles} 
					onChange={(receipts)=>{}}/>
			</Dialog>
		)
    }
    
	renderModalLinkTranMulti() {
		return (
			<LinkTran
				display={this.state.open.linkModal}
				onCancel={() => {
					this.handleCloseModal();
				}} 
				onSubmit={() => {
						this.handleCloseModal();
						//TODO: Provide this option as a setting.
						//this.handleRefreshRequest();
						this.props.enqueueSnackbar('Please refresh the account to see link (IO) status', {
							variant : 'info'
						});
                }} 
                onError={(msg)=>{
                    this.props.enqueueSnackbar(msg, { variant : 'error' });
                }}
				tranTypes={this.state.tranTypesCrDr}
				account={this.state.selectedAccount}
				isCredit={this.state.open.creditModal}
				mode={this.state.rowsSelected.length === 1 ? "single" : "multiple"}
                // accountLineUp={linkEligibleAccounts}
                accountLineUp={this.state.accountLineUp}
                // transactions={transToLink}
                transactions={this.state.rowsSelected}
			/>
		)
	}

	
	renderModalCopyTranMulti() {
		return (
			<CopyTran
				display={this.state.open.copyModal || this.state.open.moveModal}
				onCancel={() => {
					this.handleCloseModal();
				}} 
				onSubmit={() => {
						this.handleCloseModal();
						//TODO: Provide this option as a setting.
						//this.handleRefreshRequest();
						// this.props.enqueueSnackbar('Please refresh the account to see link (IO) status', {
						// 	variant : 'info'
						// });
				}} 
				tranTypes={this.state.tranTypesCrDr}
				account={this.state.selectedAccount}
				isCredit={this.state.open.creditModal}
				mode={this.state.open.copyModal ? "copy" : "move"}
				accountLineUp={this.state.accountLineUp}
				transactions={this.state.rowsSelected}
				onError={() => {
					this.handleCloseModal();
				}}
			/>
		)
	}
	
	renderWidgetYearlyTrend() {
		return (
			<Dialog open={this.state.open.widgetYearlyTrends}
				fullWidth={true}
				maxWidth="md"
				hideBackdrop={true}
				style={{modal: { padding: 0 }}}
				onClose={(e, reason)=>{
					if (reason === "backdropClick") this.handleCloseModal()
				}}
				PaperProps={{ style: { backgroundColor: Constants.COLORs.VANKAYA_250 }}}>
				<WidgetYearlyTrend 
					title={this.state.selectedAccount.name} />
			</Dialog>
		)
	}

	renderWidgetTranTypeSplits() {
		return (
			<Dialog open={this.state.open.widgetTranTypeSplits}
				fullWidth={true}
				maxWidth="lg"
				hideBackdrop={true}
				style={{modal: { padding: 0 }}}
				onClose={(e, reason)=>{
					if (reason === "backdropClick") this.handleCloseModal()
				}}
				PaperProps={{ style: { backgroundColor: Constants.COLORs.VANKAYA_250 }}}>
				<WidgetTranTypeSplits 
					title={this.state.selectedAccount.name} />
			</Dialog>
		)
	}

	renderChitPaymentDashboard() {
		return (
			<Dialog open={this.state.open.chitPaymentDashboard}
				fullWidth={true}
				maxWidth="lg"
				hideBackdrop={true}
				style={{modal: { padding: 0 }}}
				onClose={(e, reason)=>{
					if (reason === "backdropClick") this.handleCloseModal()
				}}
				PaperProps={{ style: { backgroundColor: Constants.COLORs.SABBU }}}>
				{this.state.open.chitPaymentDashboard && <ChitPayDashboard />}
			</Dialog>
		)
	}
}

Tran.propTypes = {
	classes : PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(withStyles(TranStyle)(Tran)));
