import ApiHandler from './ApiHandler';
import Constants from '../utils/constants';

export default class 
WidgetHandler extends ApiHandler {

    baseUrl = Constants.URLs.WIDGETS;

    getUserCounts() {
        return new Promise((resolve, reject)=>{
            this.getInstance().get(this.baseUrl + '/agg/counts/user').then((response) => {
                resolve(response.data)
            }).catch(this.handleError);
        });
    }

    fetchAccountYearlyTrend = (ac) => {
        return new Promise((resolve, reject)=>{
            this.nav.showOverlay();
            this.getInstance().get(this.baseUrl + '/agg/yearly/' + ac).then((response) => {
                resolve(response.data);
            }).catch(()=>{
                this.handleError(`Error while fetching account yearly trend.`);
            }).finally(()=>this.nav.hideOverlay())
        });
    }

    getAccountYearlyTrend(unset=false) {
        let ac = this.getActiveAccount();
		if (!ac) {
			this.showWarn('Please select an account to fetch yearly trend.');
			return;
		}
        return this.cachedResponse(unset, Constants.INDEXED_KEYS.ACCOUNT_YEARLY_TREND + '_' + ac, this.fetchAccountYearlyTrend, [ac]);
    }

    
    fetchAccountTranTypeSplits = (ac) => {
        return new Promise((resolve, reject)=>{
            this.nav.showOverlay();
            this.getInstance().get(this.baseUrl + '/stats/trantype/' + ac).then((response) => {
                resolve(response.data);
            }).catch(()=>{
                this.handleError(`Error while fetching account trantype splits.`);
            }).finally(()=>this.nav.hideOverlay())
        });
    }

    getAccountTranTypeSplits(unset=false) {
        let ac = this.getActiveAccount();
		if (!ac) {
			this.showWarn('Please select an account to fetch tran type splits.');
			return;
		}
        return this.cachedResponse(unset, Constants.INDEXED_KEYS.ACCOUNT_TRANTYPE_SPLITS + '_' + ac, this.fetchAccountTranTypeSplits, [ac]);
    }


}