import React, { Component } from 'react';
import { Button, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import LoadingOverlay from '../common/loadingOverlay';
import {TranStyle} from './TranStyle';
import './Tran.css'

import mapDispatchToProps from '../actions/dispatchProps';
import mapStateToProps from '../actions/stateToProps';
import { withSnackbar } from 'notistack';
import { AccountHandler } from '../api';
import Constants from '../utils/constants';
import { getInstance } from '../utils/axiosLoader';

class TranChitActions extends Component {
    constructor(props) {
		super(props);
		this._accountHandler = new AccountHandler(this);
	}

	state = {
		openOverlay : false,
	};

	handleUpdateChitStatusBulk = (status) => {
		let account = this.props.selectedAccount;
		console.log('account selected', account);

		let _transactions = this.props.rowsSelected;
		if (!_transactions) {
			this.props.enqueueSnackbar('Select a row to update status.', {
				variant : 'warning'
			});
			return;
		}
		let ids = _transactions.reduce((arr, tran)=>{
			arr.push(tran._id);
			return arr;
		},[]);
		console.log('transaction ids selected', ids);

		if (!['paid', 'unpaid'].includes(status)) {
			this.props.enqueueSnackbar('Only valid statuses are paid or unpaid.', {
				variant : 'warning'
			});
			return;
		}
		this.setState({
			openOverlay : true
		});
		// _transaction.chit_info.status = status;
		getInstance()
			.patch(`${Constants.URLs.TRANS}/chit-tran-status/trans`, {account, status, ids})
			.then(async (response) => {
				if (response.status === 400) {
					console.log(response)
					this.props.enqueueSnackbar('Error: An internal error occured while saving statuses.', {
						variant : 'error'
					});
				}
				else {
					if (response.data.message) {
						this.props.enqueueSnackbar(response.data.message, {
							variant : 'success'
						});
					} else {
						this.props.enqueueSnackbar('Transaction statuses updated successfully', {
							variant : 'success'
						});
					}
				}
			})
			.catch((error) => {
				console.log(error);
                let msg = 'An error occurred from the server';
                try {
                    if (error.response.data.message) msg = error.response.data.message
                } catch (error) {}
				this.props.enqueueSnackbar(msg, {
					variant : 'error'
				});
			})
			.finally(()=>{
				this.setState({
					openOverlay: false
				});
				if(this.props.onRefreshRequest)
				this.props.onRefreshRequest()
			})
	}
	

	handleDeleteLastTerm = () => {
		this.setState({
			openOverlay : true
		});
		getInstance()
			.post(`${Constants.URLs.TRANS}/resetchitterm/ac/${this.props.selectedAccount._id}`, {})
			.then(async (response) => {
				this.props.enqueueSnackbar('Last term deleted successfully', {
					variant : 'success'
				});
			})
			.catch((error) => {
				console.log(error);
                let msg = 'An error occurred from the server';
                try {
                    if (error.response.data.message) msg = error.response.data.message
                } catch (error) {}
				this.props.enqueueSnackbar(msg, {
					variant : 'error'
				});
			})
			.finally(()=>{
				this.setState({
					openOverlay: false
				});
				if(this.props.onACForceRefreshRequest) this.props.onACForceRefreshRequest();
				if(this.props.onRefreshRequest) this.props.onRefreshRequest()
				if(this.props.onReloadRequest) this.props.onReloadRequest()
			})
	}

	render() {
		let _tran = this.props.rowsSelected[0];
		let _status = _tran?.chit_info?.status || 'unknown';
		let {owner, admin, editor, viewer} = this.props.accountRoles;
		return (this.props.selectedAccount.type === 'CHIT' && 
			// <Grid container id="trans-chit-actions">
				<Grid item lg={5} md={5} sm={12} xs={12} id="trans-chit-actions-left">
					{this.props.selectedAccount.chit_info.nextTerm 
						&& this.props.selectedAccount.chit_info.nextTerm > 0 
						&& owner
						&& <Button disabled={false}
							onClick={() => this.props.onAddChitTerm()}
							variant="contained"
							size="small"
							color="default">New CHIT Term</Button>}
					{_tran && _status !== 'paid' && <Button disabled={viewer}
							onClick={() => this.handleUpdateChitStatusBulk('paid')}
							variant="contained"
							size="small"
							color="primary">PAID</Button>}
					{_tran && _status !== 'unpaid' && <Button disabled={viewer}
							onClick={() => this.handleUpdateChitStatusBulk('unpaid')}
							variant="contained"
							size="small"
							color="secondary">UNPAID</Button>}
					{this.props.enableChitPaymentDashboard && <Button disabled={!(owner || admin || editor)}
							onClick={() => this.props.onDashboard()}
							variant="contained"
							size="small"
							color="secondary">Payment Dashboard</Button>}
					{this.props.enableDeleteLastChitTerm 
						&& this.props.trans.length > 0 
						&& owner
						&& <Button disabled={!(owner || admin)}
							onClick={() => this.handleDeleteLastTerm()}
							variant="contained"
							size="small"
							color="secondary">Delete Last Term</Button>}
					
					{this.state.openOverlay && <LoadingOverlay />}
				</Grid>
			// </Grid>
		)
	}

}

TranChitActions.propTypes = {
	classes : PropTypes.object.isRequired,
	history: PropTypes.any,
	selectedAccount: PropTypes.object.isRequired,
	trans: PropTypes.array.isRequired,
	rowsSelected: PropTypes.array.isRequired,
	accountRoles: PropTypes.object.isRequired,
	isCompact: PropTypes.bool.isRequired,		//this.state.width <= 612
	onOpenModal: PropTypes.func.isRequired,

	// enabledAdd: PropTypes.bool.isRequired,
	// enabledDelete: PropTypes.bool.isRequired,
	// enabledUpdate: PropTypes.bool.isRequired,
	// enabledBulkUpdate: PropTypes.bool.isRequired,
	// enabledAttach: PropTypes.bool.isRequired,
	// enabledOpenAttach: PropTypes.bool.isRequired,
	// enabledLink: PropTypes.bool.isRequired,
	// enabledUnlink: PropTypes.bool,
	// enabledCopy: PropTypes.bool.isRequired,
	// enabledMove: PropTypes.bool.isRequired,
	// enabledClone: PropTypes.bool.isRequired,
	// enableSubmitOrder: PropTypes.bool.isRequired,
	enableAddChitTerm: PropTypes.bool.isRequired,
	// gridEditMode: PropTypes.bool.isRequired,
	enableChitPaymentDashboard: PropTypes.bool.isRequired,
	enableDeleteLastChitTerm: PropTypes.bool.isRequired,

	// onRefreshTranGrid: PropTypes.func,
	onACForceRefreshRequest: PropTypes.func,
	onRefreshRequest: PropTypes.func,
	onReloadRequest: PropTypes.func,
	// onEditMode: PropTypes.func,
	// onSubmitNewTransactionOrder: PropTypes.func,
	// onDownloadTrans: PropTypes.func,
	// onDeleteTrans: PropTypes.func,
	// onDeleteAccount: PropTypes.func,
	// onCreateColumnFilter: PropTypes.func,
	// onDestroyColumnFilter: PropTypes.func,
	// onUpdateEnableProperty: PropTypes.func
	onAddChitTerm: PropTypes.func.isRequired,
	onDashboard: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(withStyles(TranStyle)(TranChitActions)));
