import React from 'react';
import Constants from '../utils/constants';
import { connect } from 'react-redux';
import mapStateToProps from '../actions/stateToProps';
import './layoutstyle.css';

class SideBar extends React.Component {

    state = {
        navItems: []
    }

    handleLoadMenuItems() {
        let path = window.location.pathname.toLowerCase();
        let navItems = [];
        let items = Constants.SIDEBAR_ITEM_LIST;
        items.forEach((item)=>{
            item.active = (item.path !== '/' && path.startsWith(item.path)) || (path === item.path);
            navItems.push(
                <li key={item.name} className={`nav-item ${item.active ? 'active' : ''}`}>
                    <a className="nav-link" href={item.path}>
                        <i className={`nc-icon ${item.icon} icon-bold`}></i>
                        <p>{item.name}</p> 
                    </a>
                </li>
            );
        })
        this.setState({ navItems: navItems });
    }

    componentDidMount() {
        this.handleLoadMenuItems();
    }
    

    renderAuthenticatedView() {
        // console.log('path', path);
        return (
            <div className="sidebar"  
                data-image="/assets/img/sidebar-3.jpg"  
                data-color="orange">
                <div className="sidebar-wrapper">
                    <div className="logo">
                        <a href="/" className="simple-text" id="sidebar-logo-link">
                            Gumasta
                        </a>
                    </div>
                    <ul className="nav">
                        {this.state.navItems}
                    </ul>
                    <div className="footer">
                        PlaForm: {this.props.appState.platForm}
                        <br />
                        viewPort: {this.props.appState.viewPort}
                        <br />
                        width: {this.props.appState.width}
                    </div>
                </div>
        </div>
        )
    }

    renderUnauthenticatedView() {
        // console.log('path', path);
        return (
            <div className="sidebar"  
                data-image="/assets/img/sidebar-3.jpg"  
                data-color="green">
                <div className="sidebar-wrapper">
                    <div className="logo">
                        <a href="/" className="simple-text">
                            PFM
                        </a>
                    </div>
                    <ul className="nav">
                    </ul>
                </div>
        </div>
        )
    }
    render() {
        return this.renderAuthenticatedView();
    }
}

export default connect(mapStateToProps)(SideBar);