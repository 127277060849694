import Constants from './constants';
import jwt from 'jsonwebtoken';
import { WebClientStore } from 'web-client-store';

let {Cookie, Session, Local} = WebClientStore;
let {SESSION_KEYS, COOKIE_KEYS, LOCAL_KEYS} = Constants;

export const getTokenExpiry = () => {
  let exp = 0;
  let time = 'expired';
  try {
    const token = getSession();
    if (token) {
      var decodedToken=jwt.decode(token, {complete: true});
      exp = decodedToken.payload.exp;
    }
  } catch (error) {
    //
  } finally {
    if (exp && exp > 0) {
      let x = (exp - ((new Date()).getTime() / 1000));
      // let m = parseInt(x / 60);
      // if (m > 59) {
      //   let h = parseInt()
      //   time = `${}`
      // }
      // let s = parseInt(x - (m * 60));
      // time = `${m.toFixed(0)} Min ${s} Sec`;
      time = Constants.secondsToDhmsTime(x);
    }
  }
  return time;
}

export const isAuthenticated = (caller="others") => {
  // console.log('caller:', caller);
  const token = getSession();
  // let session = false;
  let isExp = false;
  try {
    if (token) {
      // console.log('token', token);
      // const base64Url = token.split('.')[1];
      // const base64 = base64Url.replace('-', '+').replace('_', '/');
      // // what is window.atob ?
      // // https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/atob
      // session = JSON.parse(window.atob(base64));
      var decodedToken=jwt.decode(token, {complete: true});
      // console.log('decoded', decodedToken);
      var dateNow = new Date();
      // console.log('dateNow.getTime()', dateNow.getTime() / 1000)
      isExp = (decodedToken && decodedToken.payload && decodedToken.payload.exp > (dateNow.getTime() / 1000));
      // isExp ? console.info('Session is active until' + Constants.timeAgo(decodedToken.payload.exp * 1000)) :
      // console.warn('Please login, your session is expired, ' + decodedToken.payload.exp + ' ' + Constants.timeAgo(decodedToken.payload.exp * 1000));
    }
  } catch (error) {
    console.log(error);
  }

  return isExp;
}

export const isAuthorizedSiteAdmin = (caller="any") => {
  console.log('isAuthorizedSiteAdmin()->caller:', caller);
  let previleges = Session.get(SESSION_KEYS.USER_PREVILEGES);
  console.log('retrieved previleges', previleges);
  if (previleges) {
    let _retrieved = JSON.parse(previleges);
    return _retrieved && _retrieved.isAdmin && _retrieved.isAdmin === true;
  } else return false;
}
export const getSession = () => Cookie.get(COOKIE_KEYS.SESSION);
export const setSession = (token) => { Cookie.set(COOKIE_KEYS.SESSION, token); }
export const killSession = () => { 
  Cookie.unset(COOKIE_KEYS.SESSION); 
  Local.unset(LOCAL_KEYS.REFRESH_TOKEN);

  Session.clear();
}
export const setRefreshToken = (refreshToken) => { Local.set(LOCAL_KEYS.REFRESH_TOKEN, refreshToken); };
export const getRefreshToken = () => Local.get(LOCAL_KEYS.REFRESH_TOKEN)
