import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import './index.css';
import './index-800.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { SnackbarProvider } from 'notistack';
import {configureStore} from '@reduxjs/toolkit';
import { Provider } from 'react-redux';

import rootReducer from './reducers/rootReducer';
import { setSession, killSession, setRefreshToken } from './components/utils/Session';
import AuthRoute from './components/utils/AuthRoute';
import Home from './components/layout/Home';
import Auth from './Auth';
import { WebClientStore } from 'web-client-store';
import Constants from './components/utils/constants';
let {Indexed} = WebClientStore;
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

const handleLogout = () => {
  //clear current user info
  //TODO: Check if this is being cleared before redirecting to /auth/login
  Indexed.unsetAsync(Constants.INDEXED_KEYS.USER_INFO);
  killSession();
  return <Redirect to="/auth/login" />;
}

function setAuthState(state) {
  try {
    setSession(state.authToken);
    setRefreshToken(state.authRefreshToken);
  } catch (err) { return undefined; }
}

store.subscribe(() => {
  setAuthState(store.getState())
});

const renderAuthenticatedView = () => {
  return (
    <Provider store={store}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        autoHideDuration={4000}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/logout" component={handleLogout} />
            <AuthRoute exact path="/" component={Home} />
            <AuthRoute path="/auth" component={Auth} />
            <Route path="/" component={App} />
          </Switch>
        </BrowserRouter>
      </SnackbarProvider>
    </Provider>
  )
}

ReactDOM.render(
  renderAuthenticatedView(),
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
