import React, { Component } from "react";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import {getInstance} from '../utils/axiosLoader';
import Constants from '../utils/constants'
import mapDispatchToProps from '../actions/dispatchProps';
import LoadingOverlay from '../common/loadingOverlay';
import './authstyle.css';
import { WebClientStore } from 'web-client-store';
let {Session, Indexed} = WebClientStore;
let _this=null;

class Login extends Component {
    constructor(props) { 
        super(props) 
        this.handleFieldChange = this.handleFieldChange.bind(this);
        _this = this;
    };

    state = {
        openOverlay: false,
        form: {
            email: '',
            password: '',
            staySignedIn: false,
        }
    };
    
    componentDidMount = () => {
		try {
			const {match: {params}} = this.props;
			if (params.email) {
                console.log("setting param email:" + params.email)
                let _form = this.state.form;
                _form.email = params.email
				this.setState({ form: _form })
			}	
		} catch (error) {
			console.log(error)
		}
	};

    handleFieldChange = (e) => {
        let _form = this.state.form;
        _form[e.target.name] = e.target.value;
        this.setState({ form: _form });
    }

    handleCheckboxChange = (e) => {
        let _form = this.state.form;
        _form.staySignedIn = e.target.checked;
        this.setState({ form: _form });
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        let instance = getInstance();
        //instance.defaults.headers['Content-Type'] = 'application/json';
        this.setState({ openOverlay: true })
        await instance
            .post(Constants.URLs.AUTH.LOGIN, this.state.form)
            .then(async (response) => {
                this.setState({ openOverlay: false })
                if (response.data.token) {
                    let {user, previleges} = response.data;
                    let {id} = user;
                    //TODO: Remove this to Index db?
                    if (previleges) {
                        Session.set(Constants.SESSION_KEYS.USER_PREVILEGES, JSON.stringify(previleges));
                    } else {
                        Session.unset(Constants.SESSION_KEYS.USER_PREVILEGES)
                    }
                    Indexed.setAsync(Constants.INDEXED_KEYS.USER_INFO, {id});
                    this.props.changeAuthToken(response.data.token)
                    this.props.changeRefreshToken(response.data.refreshToken)
                    this.props.enqueueSnackbar(response.data.message, {variant: 'success'});
                    _this.props.history.push("/dashboard");
                } else {
                    this.props.enqueueSnackbar('An internal error occured during login. Please try again.', {variant: 'warning'});
                }
            })
            .catch((err) => {
                this.setState({ openOverlay: false })
                console.log(err);
                let msg = 'Error: ' + err.message;
                if (err.response && err.response.data && err.response.data.message) {
                    msg = err.response.data.message;
                }
				this.props.enqueueSnackbar(msg, {variant: 'error'});
            });
    }
    
    renderLoginForm() {
      return (
        <form className="form" method="" /* action="" */ /* onSubmit={this.handleSubmit} */>
            <div className="card card-login text-center">
                <div className="card-header ">
                    <div className="row  justify-content-center">
                        <div className="header-text">
                            <h3 className="card-title">Gumasta</h3>
                            <h6 className="card-subtitle">Free ledger to care your monies</h6>
                            <hr />
                            <h3 className="header text-center">Login</h3>
                        </div>
                    </div>
                </div>
                <div className="card-body ">
                    <div className="card-body">
                        <div className="form-group">
                            <label>Email address</label>
                            <input name="email" type="email" placeholder="Enter email" defaultValue={this.state.form.email} className="form-control"  onChange={this.handleFieldChange} />
                        </div>
                        <div className="form-group">
                            <label>Password</label>
                            <input name="password" type="password" placeholder="Password" className="form-control"  onChange={this.handleFieldChange} />
                        </div>
                        <div className="form-group">
                            <div className="form-check">
                                <label className="form-check-label">
                                    <input className="form-check-input" 
                                    name="staySignedIn" type="checkbox" checked={this.state.form.staySignedIn}  
                                    onChange={this.handleCheckboxChange} />
                                    <span className="form-check-sign"></span>
                                    {`Stay Logged in ${window.isAndroid() ? 'device' : ''} for a week`}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-footer ml-auto mr-auto">
                    <button type="submit" onClick={this.handleSubmit} className="btn btn-warning btn-wd">Login</button>
                    <hr />
                    <a href="/auth/register">Not a member? Register here</a>
                    <br />
                    <a href="/auth/forgotpwd">Forgot Password? Reset here</a>
                </div>
            </div>
        </form>
      )
    }

    render() {
      return (
        <div className="container auth-form-container">
          <div className="row">
            <div className="col-md-6 mr-auto ml-auto">
              {this.renderLoginForm()}
            </div>
          </div>
          {this.state.openOverlay && <LoadingOverlay />}
        </div>
      )
    }
}

Login.propTypes = {};

export default connect(null, mapDispatchToProps)(withSnackbar(Login));